import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { Icon } from './'
const breakfastMenu = [
    {
        header:'Breakfast Favourites',
        items:[
            {
                name:'Steak & Eggs Benedict',
                description:['5 oz. sirloin, 2 poached eggs presented on ham served on an english muffin, topped with creamy hollandaise sauce.  Served with homefries'],
                price:['22.95']
            },
            {
                name:'French Toast',
                description:['3 slices of french toast served with syrup and butter','Add bacon, ham or sausage'],
                price:['11.95','5.50'],
            },
            {
                name:'Pancakes',
                description:['3 pancakes served with syrup and butter','Add bacon, ham or sausage'],
                price:['10.75','5.50']
            },
            {
                name:'Kenora Classic',
                description:['2 eggs prepared any style, 2 pancakes, bacon, ham or sausage, homefries and toast','Substitute french toast'],
                price:['16.95','0.75']
            },
            {
                name:'Eggs Benedict',
                description:['2 poached eggs presented on ham served on an english muffin topped with creamy hollandaise sauce.  Served with homefries and toast'],
                price:['16.95']
            },
            {
                name:'Hearty Affair',
                description:['3 eggs prepared any style, double bacon ham or sausage.  Double order of homefries and toast'],
                price:['20.95']
            },
            {
                name:'Executive Special',
                description:['Steak & eggs served with homefries & toast'],
                price:['19.95']
            },
            {
                name:'Breakfast Wrap',
                description:[`3 eggs, green and red pepper, onions, ham & cheese, wrapped in a tortilla shell served with homefries.  Available on white or whole wheat`],
                price:['13.25']
            },
            {
                name:'Belgium Waffles',
                description:[`3 waffles served with syrup & butter`,`Add strawberries and whipping cream`],
                price:['10.75','3.25']    
            },
            {
                name:'Blueberry Pancakes',
                description:['3 pancakes with blueberries served with syrup and butter','Add bacon, ham or sausage'],
                price:['10.75','5.50']
            }
        ]
    },
    {
        header:'Omelet Station',
        description:'Three eggs served with homefries and toast',
        items:[
            {
                name:'Plain | 9 Ham | 11 Cheese',
                description:['','Add any topping'],
                price:['11','1.75'],

            },
            {
                name:'Western',
                description:['Ham, peppers and onions','Add cheese'],
                price:['13.75','1.75']
            },
            {
                name:'Spanish',
                description:'Peppers, onions, tomatoes, mushrooms, salsa and cheese',
                price:'14.75'
            },
            {
                name:'Veggie',
                description:['Peppers, onions, tomatoes and mushrooms','Add cheese'],
                price:['13.75','1.75']
            }
        ]
    },
    {
        header:'Sunset Inn Breakfast Special',
        description:'Served daily until 11:00am',
        items:[
            {
                description:['2 eggs any style, bacon, ham or sausage, homefries & toast','Substitute tomatoes instead of homefries'],
                price:['10.25','0.75']
            },
        ]
    },
    {
        header:'Side Orders',
        items:[
            {
                name:'Muffins',
                description:'Bran or blueberry',
                price:'2.85'
            },
            {
                name:'Bacon, ham or sausage',
                price:'5.50'
            },
            {
                name:'Cold cereal',
                description:'Corn Pops, Fruit Loops or Raisin Bran',
                price:'5.00'
            },
            {
                name:'Toast & Jam',
                price:'4.85'
            },
            {
                name:'Oatmeal',
                description:['','Add raisins'],
                price:['7.50','0.25']
            },
            {
                name:'English muffin',
                price:'4.25'
            },
            {
                name:'Grilled cinnamon bun',
                price:'7.50'
            },
            {
                name:'1 egg',
                price:'2.25'
            },
            {
                name:'2 eggs',
                price:'4.25'
            },
            {
                name:'Fresh fruit cup',
                price:'8.25'
            },
            {
                name:'Egg & muffin',
                description:'with cheese',
                price:'8.25'
            },
            {
                name:'1 Pancake',
                description:['','Add Blueberries'],
                price:['3.60','3.25']
            },
            {
                name:'Homefries',
                price:'8.50'
            },
            {
                name:'Sliced fresh tomato',
                price:'2.70'
            },
            {
                name:'Strawberries & whipped cream',
                price:'3.25'
            },
            {
                name:'Breakfast poutine',
                description:'Homefries, cheese, hollandaise sauce',
                price:'12.75'
            },
            {
                name:'Hollandaise sauce',
                price:'3.00'
            }
        ]
    },
]

const lunchMenu = [
    {
        header:'Burgers',
        description:'Includes soup, tossed salad or fries',
        items:[
            {
                name:'',
                description:['Add gravy','Substitute caesar salad','Substitute greek salad','Substitute sweet potato fries'],
                price:['1.85','1.95','2.75','2.75']
            },
            {
                skip:true
            },
            {
                name:'Quarter pound burger',
                description:'Lettuce, tomato, onions, mustard, mayo & relish',
                price:'13.95'
            },
            {
                name:'Sunset Inn Capricorn burger',
                description:'Two 1/4lb sirloin burgers made in house, includes cheese, bacon, lettuce, tomato, onions, mayo, mustard & relish',
                price:'19.95'
            },
            {
                name:'Swiss & bacon burger',
                description:'Lettuce, tomato, onions, mustard, relish & melted swiss cheese',
                price:'17.95'
            },
            {
                name:'Breaded turkey burger',
                description:'Served on a hamburger bun with lettuce, tomato & mayo',
                price:'16.95'
            },
            {
                name:'Walleye burger (Seasonal)',
                description:'Battered walleye crispy deep fried with lettuce, tomato & tartar sauce, served on a herb & garlic hamburger bun',
                price:'16.95'
            },
            {
                name:'Veggie burger',
                description:'Veggie patty charbroiled with bbq sauce, mustard, relish, lettuce, tomato, onion & mayo, served on a hamburger bun',
                price:'14.95'
            },
        ]
    },
    {
        header:'Sandwiches',
        items:[
            {
                name:'',
                description:['Add soup, tossed salad or fries','Substitute caesar salad','Substitute greek salad','Add cheese'],
                price:['3.00','4.95','5.75','1.95']
            },
            {
                skip:true
            },
            {
                name:'Grilled cheese',
                description:'3 pieces of bread, cheddar melted to perfection',
                price:'8.75'
            },
            {
                name:'Western sandwich',
                description:'2 eggs, ham, peppers & onions',
                price:'9.75'
            },
            {
                name:'BLT sandwich',
                description:'Bacon, lettuce, tomato & mayo',
                price:'9.75'
            },
            {
                name:'Turkey & Mayo',
                price:'8.25'
            },
            {
                name:'Ham',
                price:'7.75'
            },
            {
                name:'Corned beef',
                price:'9.50'
            },
            {
                name:'Fried egg',
                price:'8.00'
            },
            {
                name:'Egg salad',
                price:'8.00'
            }
        ]
    },
    {
        header:'Salads',
        description:'All salads include a slice of garlic toast',
        items:[
            {
                name:'',
                description:['Add plain or teriyaki chicken','Extra garlic toast'],
                price:['4.95','1.75']
            },
            {
                skip:true
            },
            {
                name:'Greek salad',
                description:'Crisp romaine, red onions, black olives, tomatoes, cucumbers & feta',
                price:'16.75'
            },
            {
                name:'House salad',
                description:'Lettuce, carrots, cucumbers, tomatoes, red and green peppers, cabbage, choice of dressing',
                price:'13.00'
            },
            {
                name:'Spinach & feta salad',
                description:'Spinach, mushrooms, red onions, topped with feta & greek dressing',
                price:'16.75'
            },
            {
                name:`Chef's salad`,
                description:'House salad, fresh turkey & ham, topped with egg and cheese',
                price:'18.95'
            },
            {
                name:'Taco salad',
                description:'Lettuce, tomatoes, green onions, cheese, taco meat served in a taco shell',
                price:'15.95'
            },
            {
                name:'California salad',
                description:'Lettuce, carrots, cucumbers, cranberries, sliced pecans & poppyseed dressing',
                price:'15.95'
            },
            {
                name:'Caesar salad',
                description:'Romaine, croutons, bacon bits topped with parmesan',
                price:'15.95'
            },
            {
                skip:true
            }
        ]
    },
    {
        header:'Soups',
        items:[
            {
                name:'Soup of the Day',
                description:['Cup','Bowl'],
                price:['3.25','8.75']
            },
            {
                name:'French Onion',
                price:'9.25'
            }
        ]
    },
    {
        header:'Wraps & Quesadillas',
        description:'Available on white or whole wheat wraps.  Includes soup, tossed salad, or fries',
        items:[
            {
                name:'',
                description:['Substitute caesar salad','Substitute greek salad','Substitute sweet potato fries'],
                price:['1.95','2.75','2.75']
            },
            {
                skip:true
            },
            {
                name:'Chicken caesar wrap',
                description:'Romaine lettuce, chicken breast, bacon bits & caesar dressing',
                price:'17.95'
            },
            {
                name:'Taco beef wrap',
                description:'Lettuce, cheese, tomatoes, green onions & taco beef',
                price:'17.95'
            },
            {
                name:'Chicken wrap',
                description:'Chicken breast, lettuce, tomato, cheese & ranch dressing',
                price:'17.95'
            },
            {
                name:'Veggie quesadilla',
                description:'Mushrooms, red and green peppers, onions, tomatoes & cheese',
                price:'18.95'
            },
            {
                name:'Taco beef quesadilla',
                description:'Taco beef, green onions, lettuce, tomatoes & cheese',
                price:'18.95'
            },
            {
                name:'Chicken quesadilla',
                description:'Grilled chicken breast, red & green peppers, onions & cheese',
                price:'18.95'
            }
        ]
    },
    {
        header:'Lunch Favourites',
        description:'Includes soup, tossed salad, or fries',
        items:[
            {
                name:'',
                description:['Substitute caesar salad','Substitute greek salad','Substitute sweet potato fries'],
                price:['1.95','2.75','2.75']
            },
            {
                skip:true
            },
            {
                name:'Steak sandwich',
                description:['Top sirloin 5oz steak on garlic toast','Add sauteed mushrooms','Add sauteed onions'],
                price:['16.95','1.95','1.95']
            },
            {
                name:'Patty melt',
                description:'Homemade burger patty, sauteed onions, mozza cheese on grilled rye bread',
                price:'14.75'
            },
            {
                name:'Hot turkey',
                description:'Fresh turkey patty sandwiched between two slices of bread, smothered with gravy',
                price:'16.95'
            },
            {
                name:'Hot hamburger',
                description:'Fresh homemade beef patty sandwiched between two slices of bread, smothered with gravy',
                price:'16.95'
            },
            {
                name:'Honey dill chicken grill',
                description:['Breaded chicken strips with honey dill sauce, tex mex cheese, smothered between two slices of garlic toast','Add extra honey dill sauce'],
                price:['16.95','1.95']
            },
            {
                name:'Clubhouse sandwich',
                description:'Fresh turkey breast, bacon, lettuce, tomato, cheeso & mayo between three slices of bread',
                price:'16.95'
            },
            {
                name:'Breaded shrimp',
                description:'6 breaded shrimp served with cocktail sauce & lemon',
                price:'14.95'
            },
            {
                name:'Chicken strips',
                description:'4 succulent deep fried breaded chicken strips served with choice of sauce',
                price:'16.95'
            },
            {
                name:'Chicken club sandwich',
                description:'Chicken breast, cheese, bacon, lettuce, tomato & mayo served on a herb and garlic bun',
                price:'16.95'
            },
            {
                name:'Beer battered haddock',
                description:'Beer battered haddock deep fried and served with tartar sauce & lemon',
                price:'16.95'
            },
            {
                name:'Prime rib',
                description:'5oz cut charbroiled to perfection, served with au jus',
                price:'18.95'
            },
            {
                name:'Perogies',
                description:'6 deep fried perogies smothered with bacon, green onions, served with sour cream',
                price:'15.75'
            },
            {
                name:'The Reuben',
                description:'Corned beef, sauerkraut, melted swiss chesse, thousand island dressing, grilled between rye bread',
                price:'15.75'
            },
            {
                skip:true
            }
        ]
    },
    {
        header:'Snacks & Stuff',
        items:[
            {
                name:'Homemade fries',
                description:['','Add Gravy'],
                price:['9.00','1.95']
            },
            {
                name:'Poutine',
                description:'Cheese and gravy',
                price:'12.75'
            },
            {
                name:'Poutine Supreme',
                description:'Bacon bits, green onions, tomatoes, cheese & gravy',
                price:'17.75'
            },
            {
                name:'Sweet potato fries',
                price:'14.75'
            },
            {
                name:'Mozza sticks',
                price:'14.75'
            },
            {
                name:'Garlic toast basket',
                description:['','With cheese'],
                price:['7.95','9.95']
            },
            {
                name:'Onion ring basket',
                price:'13.75'
            },
            {
                name:'Spinach & artichoke dip',
                description:'Served with grilled flatbread',
                price:'16.75'
            },
            {
                name:'Popcorn shrimp',
                description:'A basket of shrimp, once you pop...',
                price:'16.75'
            },
            {
                name:'Mushroom caps',
                price:'14.75'
            },
            {
                name:'Jalapeno poppers',
                price:'14.75'
            },
            {
                skip:true
            }
        ]
    },
    {
        header:'Desserts',
        items:[
            {
                name:'Vanilla ice cream',
                price:'4.00'
            },
            {
                name:'Apple crisp',
                price:'9.50'
            },
            {
                name:'Double chocolate brownie',
                description:'Served with ice cream',
                price:'9.75'
            },
            {
                name:'New York cheesecake',
                price:'8.25'
            }
        ]
    },
    {
        header:'Beverages',
        items:[
            {
                name:'Coffee or tea',
                price:'2.85'
            },
            {
                name:'Herbal tea',
                description:'Green,blueberry,camomile,cranberry,mint,lemon',
                price:'2.85'
            },
            {
                name:'Chocolate milk',
                description:['','Small'],
                price:['3.25','2.95']
            },
            {
                name:'Milk',
                description:['','Small'],
                price:['3.25','2.95']
            },
            {
                name:'Iced tea',
                description:['','Small'],
                price:['3.75','3.25']
            },
            {
                name:'Soft drinks',
                description:'Pepsi, Diet Pepsi, 7up, Ginger Ale, Orange Crush',
                price:'3.75'
            }
        ]
    },
]
const dinnerMenu = [
    {
        header:'Appetizers',
        items:[
            {
                name:'Chicken wings',
                description:'Minimum 6 jumbo wings with choice of breaded, salt & pepper, lemon pepper, honey garlic, cajun, bbq, mild, medium, hot, suicide, teriyaki',
                price:'2.45/wing'
            },
            {
                name:'Escargot',
                description:'Sauteed garlic mushrooms stuffed with escargot, topped with mozza cheese, served with garlic toast',
                price:'15.25'
            },
            {
                name:'Shrimp Au Gratin',
                description:'6 garlic shrimp, baked with mozza cheese, served with garlic toast',
                price:'15.75'
            },
            {
                name:'Spinach & artichoke dip',
                description:'Served with grilled flatbread',
                price:'16.75'
            },
            {
                name:'Nachos',
                description:['Tortilla chips, mixed cheese, onions, peppers, jalapenos, tomatoes & black olives served with salsa & sour cream','Add taco beef'],
                price:['19.95','4.00']
            },
            {
                name:'Shrimp cocktail',
                description:'6 prawns served with cocktail sauce & lemon',
                price:'15.75'
            }
        ]
    },
    {
        header:'Baskets',
        items:[
            {
                name:'Sweet potato fries',
                price:'14.75'
            },
            {
                name:'Battered onion rings',
                price:'13.75'
            },
            {
                name:'Mushroom caps',
                price:'14.75'
            },
            {
                name:'Sea salt pepper ribs',
                price:'15.75'
            },
            {
                name:'Battered mac & cheese wedges',
                price:'14.75'
            },
            {
                name:'Broccoli cheese bites',
                price:'15.75'
            },
            {
                name:'Breaded pickles',
                price:'14.75'
            },
            {
                name:'Mozzarella sticks',
                price:'14.75'
            },
            {
                name:'Jalapeno Poppers',
                price:'14.75'
            },
            {
                name:'Popcorn shrimp',
                price:'16.75'
            },
            {
                name:'Garlic toast',
                description:['','Cheesy'],
                price:['7.95','9.95']
            },
            {
                skip:true
            }
        ]
    },
    {
        header:'Salads',
        description:'All salads include a slice of garlic toast',
        items:[
            {
                name:'',
                description:['Add plain or teriyaki chicken','Extra garlic toast'],
                price:['4.95','1.75']
            },
            {
                skip:true
            },
            {
                name:'House salad',
                description:'Lettuce, carrots, cucumbers, tomatoes, red and green peppers, cabbage, choice of dressing',
                price:'13.00'
            },
            {
                name:'Greek salad',
                description:'Crisp romaine, red onions, black olives, tomatoes, cucumbers & feta',
                price:'16.75'
            },
            {
                name:'Caesar salad',
                description:'Romaine, croutons, bacon bits topped with parmesan',
                price:'15.95'
            },
            {
                name:'California salad',
                description:'Lettuce, carrots, cucumbers, cranberries, sliced pecans & poppyseed dressing',
                price:'15.95'
            },
            {
                name:'Spinach & feta salad',
                description:'Spinach, mushrooms, red onions, topped with feta & greek dressing',
                price:'16.75'
            },
            {
                skip:true
            }
        ]
    },
    {...lunchMenu[3]},
    {
        header:'Pasta',
        description:'Served with starter soup or salad',
        items:[
            {
                name:'Chicken Florentine',
                description:'Sauteed chicken breast served on a bed of fettuccine noodles with spinach, mushrooms and creamy garlic sauce, served with garlic toast',
                price:'28.95'
            },
            {
                name:'Fettuccine Alfredo',
                description:['Fettuccine noodles in a mushroom cream sauce served with garlic toast','Add chicken','Add garlic shrimp'],
                price:['26.95','4.95','7.95']
            }
        ]
    },
    {
        header:'Entrees',
        description:'Served with starter soup or salad, choice of potato and vegetable',
        items:[
            {
                name:'',
                description:['Add breaded or garlic shrimp','Add gravy','Substitute caesar salad','Substitute greek salad'],
                price:['','7.85','1.95','2.75']
            },
            {
                skip:true
            },
            {
                name:'New York Striploin Steak',
                description:'8oz strip steak',
                price:'35.00'
            },
            {
                name:'Ribeye Steak',
                description:'8oz ribeye steak',
                price:'33.00'
            },
            {
                name:'Chicken Parmesan',
                description:'2 chicken breasts with marinara sauce, baked with mozzarella and fresh parmesan cheese',
                price:'26.95'
            },
            {
                name:'Breaded Shrimp Platter',
                description:'12 breaded shrimp deep fried til golden brown',
                price:'29.95'
            },
            {
                name:'Walleye Filet Fisherman\'s Style',
                description:'Fresh Lake of the Woods walleye, breaded and deep fried to perfection.  Available seasonally.',
                price:'33.00'
            },
            {
                skip:true
            }
        ]
    },
    {
        header:'Lighter Dining',
        description:'Served with baked, mashed, fries or rice',
        items:[
            {
                name:'',
                description:['Add gravy','Substitute caesar salad','Substitute greek salad','Substitute sweet potato fries'],
                price:['1.95','1.95','2.75','2.75']
            },
            {
                skip:true
            },
            {
                name:'Honey Dill Chicken Grill',
                description:'Breaded chicken strips with honey dill sauce, smothered with tex mex cheese, sandwiched between garlic toast',
                price:'18.95'
            },
            {
                name:'Hot Turkey',
                description:'Fresh turkey patty sandwiched between two slices of bread, smothered with gravy, served with veggies',
                price:'19.95'
            },
            {
                name:'Hot Hamburger',
                description:'Fresh homemade beef patty sandwiched between two slices of bread, smothered with gravy, served with veggies',
                price:'19.95'
            },
            {
                name:'Sunset Inn Capricorn burger',
                description:'Two 1/4lb sirloin burgers made in house, includes cheese, bacon, lettuce, tomato, onions, mayo, mustard & relish',
                price:'19.95'
            },
            {
                name:'Veggie Burger',
                description:'Veggie patty charbroiled with bbq sauce, mustard, relish, lettuce, tomato, onion & mayo, served on a hamburger bun',
                price:'14.95'
            },
            {
                name:'Steak Sandwich',
                description:['Top sirloin 5oz steak on garlic toast','Add sauteed mushrooms','Add sauteed onions'],
                price:['20.95','1.95','1.95']
            },
            {
                name:'Prime Rib on Garlic Toast',
                description:['5oz cut of prime rib, charbroiled to perfection, served on garlic toast and topped with au jus','Add mozzarella cheese','Add sauteed onions'],
                price:['20.95','1.95','1.95']
            },
            {
                name:'Chicken Strips',
                description:['4 succulent deep fried breaded chicken strips served with choice of sauce','Add extra sauce'],
                price:['18.95','0.65']
            },
            {
                name:'Beer Battered Haddock',
                description:'Beer battered haddock, deep fried, served with tartar sauce & lemon',
                price:'17.95'
            },
            {
                name:'Quarter Pound Burger',
                description:['1/4 pound sirloin homemade burger, charbroiled brilliantly, lettuce, tomato, mustard, relish & onion','Add cheese','Add bacon'],
                price:['13.95','1.95','1.95']
            },
            {
                name:'Patty Melt',
                description:'Homemade burger patty, sauteed onions, mozzarella cheese, served between grilled rye bread',
                price:'14.95'
            },
            {
                name:'The Rueben',
                description:'Corned beef, sauerkraut, melted swiss cheese, thousand island dressing, served between grilled rye bread'
            }
        ]
    },
    {
        header:'Senior Choices',
        description:'Served with baked, mashed, fries or rice',
        items:[
            {
                name:'Add gravy',
                price:'1.95'            
            },
            {
                skip:true
            },
            {
                name:'Pork Chop',
                description:['4oz pork chop charbroiled to perfection with bbq sauce','Addition chop'],
                price:['17.00','5.50']
            },
            {
                name:'Liver & Onions',
                description:['5oz tender beef liver with sauteed onions, served with veggies','Additional liver'],
                price:['18.00','5.50']
            }
        ]
    },
    {...lunchMenu[7]},{...lunchMenu[8]}
]
const beerMenu = [
    {
        header:'Draught Beer',
        items:[
            {
                name:'Bud Light',
                description:['', 'Pint','Pitcher'],
                price:['','6.50','16.50']
            },
            {
                name:'Alexander Keith\'s Indian Pale Ale',
                description:['', 'Pint','Pitcher'],
                price:['', '7.95','17.95']
            }
        ]
    },
    {
        header:'Domestic Bottled Beer',
        items:[
            {
                name:'Budweiser',
                price:'5.25'
            },
            {
                name:'Bud Light',
                price:'5.25'
            },
            {
                name:'Labatt Blue',
                price:'5.25'
            },
            {
                name:'Labatt Blue Light',
                price:'5.25'
            },
            {
                name:'Kokanee',
                price:'5.25'
            },
            {
                name:'Molson Canadian',
                price:'5.25'
            },
            {
                name:'Coors Light',
                price:'5.25'
            },
            {
                name:'Labatt Crystal',
                price:'5.25'
            },
            {
                name:'Non-Alcoholic Beer',
                price:'4.00'
            },
            {
                skip:true
            }
        ]
    },
    {
        header:'Premium & International Beer',
        items:[
            {
                name:'Corona',
                price:'6.75'
            },
            {
                name:'Stella Artois',
                price:'6.75'
            },
            {
                name:'Alexander Keith\'s Indian Pale Ale',
                price:'6.75'
            },
            {
                name:'Bud Light Lime',
                price:'6.75'
            },
            {
                name:'Heineken',
                price:'6.75'
            },
            {
                name:'MGD',
                price:'6.75'
            }
        ]
    },
    {
        header:'Coolers',
        items:[
            {
                name:'Palm Bay',
                description:["",'Ruby Grapefruit','Key Lime Cherry','White Peach Tangerine'],
                price:['','','','6.75']
            },
            {
                name:'Bacardi Breezer',
                description:['','Lemon Lime','Pina Colada'],
                price:['','','6.75']
            },
            {
                name:'Mike\'s Hard Lemonade',
                price:'6.75'
            },
            {
                name:'Wildberry',
                price:'6.75'
            },
            {
                name:'Smirnoff Ice',
                price:'6.75'
            },
            {
                skip:true
            }
        ]
    },
    {
        header:'Ciders',
        items:[
            {
                name:'Apple Somersby',
                price:'7.75'
            },
            {
                name:'Pear Somersby',
                price:'7.75'
            }
        ]
    },
    {
        header:'Seltzers',
        items:[{
            name:'Nutrl Vodka Soda',
            description:['', 'Lemon', 'Grapefruit'],
            price:['','','7.75']
        },
        {
            name:'Vizzy',
            description:['', 'Pineapple Mango','Blueberry Pomegranate'],
            price:['','','7.75']
        },]
    },
    {
        header:'Iced Teas',
        items:[
            {
                name:'American Vintage Hard Iced Tea',
                price:'6.75'
            },
            {
                name:'Twisted Tea',
                price:'6.75'
            }
        ]
    }
]
const wineMenu = [
    {
        header:'House Wines',
        items:[
            {
                name:'',
                description:['6oz','9oz','18oz','36oz'],
                price:['8.25','11.25','16.75','35.25']
            },
            {
                name:'Red',
                description:['', 'Pelee Island Shiraz','Jackson-Triggs Cabernet','Kressmann Merlot'],
                price:['','','','','']
            },
            {
                name:'Rose',
                description:['','White Zinfandel','Gallo Family Torconal'],
                price:['','','']
            },
            {
                name:'White',
                description:['','French Cross Dry White','Jackson-Triggs Pinot Grigio','Cono Sur Sauvignon Blanc'],
                price:['','','','']
            }
        ]
        
    },
    {
        header:'By The Bottle',
        items:[
            {
                name:'Yellow Tail Shiraz',
                price:'27.95'
            },
            {
                name:'J Lohr Cabernet',
                price:'41.50'
            },
            {
                name:'Two Sauvignon Blanc',
                price:'28.00'
            },
            {
                name:'J Lohr Chardonnay',
                price:'41.50'
            }
        ]
    }
]
const liquorMenu = [
    {
        header:'Sunset Specials',
        items:[
            {
                name:'Sunset Caesar',
                price:'6.75'
            },
            {
                name:'Jumbo Caesar',
                price:'8.75'
            },
            {
                name:'Long Island Iced Tea',
                price:'6.75'
            },
            {
                name:'Jumbo Long Island Iced Tea',
                price:'8.75'
            },
            {
                name:'Martini',
                description:'2oz of vodka or gin, dash of dry Vermouth',
                price:'11.00'
            },
            {
                name:'Margarita',
                description:'1.5oz of tequila & Mott\'s Margarita Mix',
                price:'9.95'
            },
            {
                name:'Daiquiri',
                description:'1.5oz of rum with Mott\'s Daiquiri Mix',
                price:'9.95'
            },
            {
                name:'Pina Colada',
                description:'1.5oz of rum with Mott\'s Pina Colada Mix',
                price:'9.95'
            }
        ]
    },
    {
        header:'Cocktails',
        items:[
            {
                name:'',
                description:'Regular cocktails 1.5oz',
                price:'7.95'
            },            {
                name:'',
                description:'Jumbo cocktails 2.5oz',
                price:'9.95'
            },
            {
                name:'Alabama Slammer',
                description:'Southern comfort, amaretto & OJ with a dash of grenadine',
                price:''
            },
            {
                name:'Silver Cloud',
                description:'Kahlua, amaretto & milk topped with whipped cream',
                price:''
            },
            {
                name:'Fuzzy Naval',
                description:'Peach schnapps & OJ',
                price:''
            },
            {
                name:'Stick Shift',
                description:'Rye, kahlua & milk',
                price:''
            },
            {
                name:'Singapore Sling',
                description:'Gin, cherry brandy & OJ with a dash of grenadine',
                price:''
            },
            {
                name:'Tequila Sunrise',
                description:'Tequila & OJ with a dash of grenadine',
                price:''
            },
            {
                name:'Blue Hawaiian',
                description:'Malibu rum, blue curacao & pineapple juice',
                price:''
            },
            {
                name:'Paralyzer',
                description:'Vodka, kahlua & milk with a splash of pepsi',
                price:''
            }
        ]
    },
    {
        header:'Shooters',
        items:[
            {name:'Sambuca', price:'6.00'},
            {name:'Jagemeister', price:'6.00'},
            {name:'Rum Chata', price:'6.00'},
            {name:'Fireball', price:'6.00'},
            {name:'Tequila Rose', price:'6.00'},
            {name:'Sour Puss', price:'6.00'}
        ]
    },
    {
        header:'Mixed Shooters',
        items:[
            {name:'Salty Balls',description:'Frangelico and butter ripple',price:'7.00'},
            {name:'Cinnamon Cindy',description:'White creme de cacao, Bailey\'s, Butter ripple & cinnamon',price:'7.00'},
            {name:'Porn Star',description:'Raspberry Sourpuss & Blue Curacao',price:'7.00'},
            {name:'Salty Balls',description:'Frangelico and butter ripple',price:'7.00'},
            {name:'Dirty Hooker',description:'Raspberry Sourpuss & Creme de Banana',price:'7.00'},
            {name:'Polar Bear',description:'Creme de Cacao and Creme de Menthe',price:'7.00'},
            {name:'Burt Reynolds',description:'Spiced rum & butter ripple',price:'7.00'},
            {name:'Rigourous Monkey',description:'Creme de Banana, Kahlua & Creme de cacao',price:'7.00'},
        ]
    }
]
const restCategories = [
    {
      name:'breakfast',
      dir:breakfastMenu,
      icon:'bacon'
    },
    {
        name:'lunch',
        dir:lunchMenu,
        icon:'lunch'
    },
    {
        name:'dinner',
        dir:dinnerMenu,
        icon:'dinner'
    }
]
const loungeCategories = [
    {
        name:'Beer & Malts',
        dir:beerMenu,
        icon:'beer'
    },
    {
        name:'Wine',
        dir:wineMenu,
        icon:'wine'
    },
    {
        name:'Liquor',
        dir:liquorMenu,
        icon:'liquor'
    }
]
const ForestRoomMenu = ({menuFor, showMenuIndex}) => {
    const [menu, setMenu] = useState(menuFor === 'restaurant' ? [breakfastMenu,lunchMenu,dinnerMenu][showMenuIndex] : beerMenu)
    const categories = menuFor === 'restaurant' ? restCategories : loungeCategories
    return(
        <>
         <RadioGroup className="w-full md:w-[50%] lg:w-[40%] flex justify-center gap-1 z-30 px-2 py-1 md:py-5" value={menu} onChange={setMenu}>
            {
              categories.map(category=>(
                <RadioGroup.Option key={category.name} className="w-1/3 md:w-full flex justify-center" value={category.dir}>
                  {({ checked }) => (
                    <div className={`box-border w-full md:w-[200px] flex flex-col md:flex-row justify-center items-center rounded-[25px] transition duration-250 border-2 ${checked ? 'bg-sun dark:bg-island elevation-5 border-island dark:border-lightblue' : 'bg-white dark:bg-transparent dark:bg-gradient-to-b dark:from-island dark:via-black dark:to-island border-transparent dark:border-sun'}`}>
                      <Icon type={category.icon} className="p-1 w-[40px] md:w-[40px] h-[40px] md:h-[50px] md:pr-[5px] fill-island dark:fill-white opacity-0 animate-fade-in animate-dur-100 animate-del-100" pathClassName={`${checked ? '' : ''}`}/>
                      <div className={`font-overlock opacity-0 animate-fade-in animate-dur-100 animate-del-100 dark:text-sun`}>
                        {
                          !checked && category.name
                        }
                      </div>
                    </div>
                    )}
                </RadioGroup.Option>
              ))
            }
            </RadioGroup>
            {
                menu.map(menuItem=>(
                    <div key={menuItem.header} className="flex flex-col items-center w-full z-30 opacity-0 animate-fade-in animate-dur-100 animate-del-100">
                    <h3 className="text-center text-4xl font-overlock py-5 w-[95%] lg:w-[50%] bg-lightblue dark:bg-island border-[15px] border-island dark:border-waterDark border-b-[2px] font-black">
                        {
                            menuItem.header
                        }
                    </h3>
                    {
                        menuItem.description &&
                        <div className="flex justify-center bg-gradient-to-l from-lightblue dark:from-island via-white dark:via-black to-lightblue dark:to-island dark:text-waterLight w-[95%] px-2 text-center lg:text-base lg:w-[50%] border-x-[15px] border-island dark:border-waterLight pt-2">
                            {
                                menuItem.description
                            }
                        </div>
                    }
                    <div className="w-[95%] lg:w-[50%] flex justify-center flex-wrap items-center bg-gradient-to-l from-lightblue dark:from-island via-white dark:via-black to-lightblue dark:to-island p-5 text-island border-[15px] border-island dark:border-waterLight border-y-0 last:border-b-[15px]">
                    {
                        menuItem.items && menuItem.items.map((item)=>(
                            <div key={item.name + item.description + item.price} className="w-full md:w-1/2 p-1">
                                {
                                    !item.skip && [item.name].flat().map((name)=>(
                                        <div key={name} className="w-full mb-1 text-base">
                                        <span className="font-bold dark:text-sun">
                                            {name}
                                        </span>
                                            {
                                                                                              
                                                        [item.description].flat().map((desc,index)=> (
                                                            (desc !== '' && item[index]?.price !== '') 
                                                                ? <div key={desc + item.price} className="contents"><span className="dark:text-white"> {desc}</span><span className="font-bold dark:text-waterLight">{[item.price].flat().map(price=>price)[index] !== '' ? ` | ${[item.price].flat().map(price=>price)[index]}` : ''}</span><br/></div>    
                                                                : <div key={desc + item.price} className="contents"><br/></div>                                  
                                                            ))
                                              
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                    </div>
                    </div>
                ))
            }
        </>
    )
}

export default ForestRoomMenu