import React, { useState} from 'react'
import { usePopper } from 'react-popper'
import { Popover, Transition } from '@headlessui/react'
import Link from 'next/link'


const SubMenu = ({name, subMenus, className, classNames}) => {
    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement, {placement:'bottom'})
    
    return(
        <Popover className={`${className || ''} text-center font-black text-black`}>
            {({open})=>(
                <>
                    <Popover.Button 
                        className="flex justify-center w-full"
                        ref={setReferenceElement}
                    >
                        <div className="bg-island w-full">
                            <div className={`w-full ${classNames.container}`}>
                                <div className={`${classNames.background
                                } dark:text-yellow dark:opacity-80`}>{name}</div>
                                <div className={`${classNames.link} relative`}>
                            {
                                name
                            }
                            </div>
                            </div>
                        </div>
                    </Popover.Button>
                    <Transition
                        show={open}
                        enter="transition duration-200 ease-out"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                    <Popover.Panel 
                        ref={setPopperElement} 
                        static 
                        as="div" 
                        style={styles.popper}
                        {...attributes.popper}
                        className="min-w-[20%] p-2 flex flex-col border-2 border-red border-t-0 bg-gradient-to-b from-island via-white to-island elevation-20"
                    >
                        {
                            subMenus?.map(({link,name})=>(
                                <div key={name} className="bg-gradient-to-l from-sun via-citron to-sun dark:from-caput dark:to-caput dark:via-red py-1 px-2 border-t border-flax relative">
                                    <div className="absolute inset-0 bg-gradient-to-l from-transparent via-white to-transparent blur opacity-60"></div>
                                    <Link key={name} className="text-xl font-overlock dark:text-flax relative" href={link}>{name}</Link>
                                </div>
                            ))
                        }
                    </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}

export default SubMenu