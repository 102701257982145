import React from 'react'
import { ArrowIcon } from './'

const BriefingLink = ({post}) => {
    return(
        <li
            //   key={post.filePath}
              className="md:first:rounded-t-lg md:last:rounded-b-lg backdrop-blur-lg bg-white dark:bg-black dark:bg-opacity-60 bg-opacity-10 hover:bg-opacity-20 dark:hover:bg-opacity-50 transition border border-gray-800 dark:border-white border-opacity-10 dark:border-opacity-10 border-b-0 last:border-b hover:border-b hovered-sibling:border-t-0"
            >
              {/* <Link
                as={`/posts/${post.filePath.replace(/\.mdx?$/, '')}`}
                href={`/posts/[slug]`}
              >
                <a className="py-6 lg:py-10 px-6 lg:px-16 block focus:outline-none focus:ring-4"> */}
                  {post.data.date && (
                    <p className="uppercase mb-3 ml-1 font-bold opacity-60">
                      {post.data.date}
                    </p>
                  )}
                  <h2 className="text-2xl dark:text-gradient-4 lg:text-lg mx-1 xl:text-2xl font-tinos">{post.data.title}</h2>
                  <hr className="w-1/6"/>
                  {post.data.description && (
                    <p className="mt-3 mx-1 text-base dark:text-gradient-4 xl:text-lg font-mutka opacity-80">
                      {post.data.description}
                    </p>
                  )}
                  <ArrowIcon className="mt-4" />
                {/* </a>
              </Link> */}
            </li>
    )
}

export default BriefingLink