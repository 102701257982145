import { useState, useEffect } from 'react';
import Image from 'next/image';
import { DateRangePicker } from 'react-date-range';
import Link from 'next/link';
import { Dialog } from '@headlessui/react';
import { format } from 'date-fns';
import { Button } from './';

const DatePicker = ({
  ranges,
  rangePickerProps,
  addArrivalDeparture = 'true',
  addButton = 'true',
}) => {
  const today = new Date();
  const [isOpen, setIsOpen] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: today,
    endDate: new Date(today.getTime() + 86400000),
    key: 'selection',
  });
  const handlePickerClick = () => {
    console.log('CLICK');
  };
  const handleSelect = (ranges) => {
    console.log('SELECTION', ranges);
    setSelectionRange(ranges.selection);
  };
  const dF = (date) => format(date, 'ccc LLL d, yyyy');
  function formatDate(date) {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();

    return `${day}%20${monthNames[monthIndex]}%20${year}`;
  }
  return (
    <>
      {addArrivalDeparture && (
        <button
          className="w-full block md:flex justify-center items-center md:p-5 md:max-h-[150px]"
          onClick={() => setIsOpen(true)}
        >
          <div
            className={`relative hidden lg:flex lg:w-[33%] h-[300px] lg:h-[auto] aspect-[863/363] opacity-40`}
          >
            <Image
              src={`https://sunset-inn-dev.s3.ca-central-1.amazonaws.com/forest-room/assets/arriving.webp`}
              alt=""
              fill
              placeholder="shimmer"
              blurDataURL='data:image/png;base64;"/placeholder.png"'
              loading="eager"
            />
          </div>
          <div className="flex flex-col py-1 md:py-10 px-2 items-center w-full md:w-auto">
            <label for="arriving-date" className="w-full mr-2 text-2xl font-orbitron font-[700] mb-2">
              Arrival
            </label>
            <input
              id="arriving-date"
              name="arriving-date"
              inputmode="none"
              readonly="readonly"
              className="bg-white dark:bg-black h-12 events-none rounded-md border-island border-2 elevation-5 text-center border-2 border-waterLight"
              value={dF(selectionRange.startDate)}
              disabled
              aria-label="Arrival Date"
            />
          </div>
          <div className="flex flex-col py-1 md:py-10 px-2 items-center  w-full md:w-auto">
            <label for="departing-date" className="w-full mr-2 text-2xl font-orbitron font-[700] mb-2">
              Departing
            </label>
            <input
              id="departing-date"
              name="departing-date"
              inputmode="none"
              readonly="readonly"
              className="bg-white dark:bg-black h-12 rounded-md border-island border-2 text-center border-2 elevation-5 border-waterDark"
              value={dF(selectionRange.endDate)}
              disabled
              aria-label="Departure Date"
            />
          </div>
          <div
            className={`relative hidden lg:flex lg:w-[33%] h-[300px] lg:h-[auto] aspect-[863/363] opacity-40`}
          >
            <Image
              src={`https://sunset-inn-dev.s3.ca-central-1.amazonaws.com/forest-room/assets/departing.webp`}
              alt=""
              fill
              placeholder="shimmer"
              blurDataURL='data:image/png;base64;"/placeholder.png"'
              loading="eager"
            />
          </div>
        </button>
      )}
      <Dialog
        className="relative w-full h-[500px] bg-white z-50"
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Dialog.Panel>
          <div className="fixed inset-0 flex items-center justify-center">
            <div
              onClick={() => {
                setIsOpen(false);
              }}
              className="absolute inset-0 bg-island opacity-40 z-10"
              aria-hidden="true"
            />
            <DateRangePicker
              className="m-y-24 max-w-lg z-20 relative bg-transparent"
              ranges={[selectionRange]}
              onChange={handleSelect}
              {...rangePickerProps}
            />
          </div>
        </Dialog.Panel>
      </Dialog>
      {addButton && (
        <div className="w-full flex justify-center">
          <Link
            href={`https://www.onressystems.com/reservations/search?property=sunset-inn&checkin=${formatDate(
              selectionRange.startDate
            )}&checkout=${formatDate(
              selectionRange.endDate
            )}&rooms=1&numchildren=0&numadults=1&code=&prodList=&lang=en`}
          >
            <Button>CHECK AVAILABILITY</Button>
          </Link>
        </div>
      )}
    </>
  );
};

export default DatePicker;
