import React from 'react'
import { Popover, Transition } from '@headlessui/react'
import Link from 'next/link'
import { SubMenu, ThemeSwitcher } from '.'

const NavMenu = ({currentPage}) => {
    const menuItems = [
        {
            id:10,
            name:'Home',
            isCurrentPage:currentPage === 'home',
            link:'/'
        },
        {
            id:0,
            name:'Near By',
            isCurrentPage:currentPage === 'nearby',
            hideSubOnMobile:true,
            mobileLink:'/near-by?d=restaurant',
            subMenus:[
                {
                    id:10,
                    name:'Attractions',
                    link:{ pathname:'/near-by', query:{ d:'attractions' } }
                },
                {
                    id:7,
                    name:'Parks and Rec',
                    link:{ pathname:'/near-by', query:{ d:'recreation' } }
                },
                {
                    id:13,
                    name:'Coffee',
                    link:{ pathname:'/near-by', query:{ d:'coffee' } }
                },
                {
                    id:11,
                    name:'Fishing',
                    link:{ pathname:'/near-by', query:{ d:'fishing' } }
                },
                {
                    id:8,
                    name:'Cars and Car Care',
                    link:{ pathname:'/near-by', query:{ d:'car' } }
                },
                {
                    id:14,
                    name:'Shopping',
                    link:{ pathname:'/near-by', query:{ d:'shopping' } }
                },
                {
                    id:9,
                    name:'For Your Pet',
                    link:{ pathname:'/near-by', query:{ d:'pet' } }
                },
                {
                    id:14,
                    name:'Indulge',
                    link:{ pathname:'/near-by', query:{ d:'indulge' } }
                },
                {
                    id:12,
                    name:'Dining',
                    link:{ pathname:'/near-by', query:{ d:'restaurant' } }
                }
            ]
        },
        {
            id:1,
            name:'The Forest Room',
           link:'./the-forest-room-restaurant',
           isCurrentPage:currentPage === 'forestroom',
        },
        {
            id:3,
            name:'The Capricorn Lounge',
           link:'./the-capricorn-lounge',
           isCurrentPage:currentPage === 'capricorn',
        },
        // {
        //     id:4,
        //     name:'Amenities',
        //     link:'./sunset-inn-amenities',
        //     isCurrentPage:currentPage === 'rooms'
        // },
        {
            id:'5',
            name:'807-468-2003',
            link:'tel:18074682003'
        }
    ].filter(link=>!link?.isCurrentPage)
    const widthClass = {
        1:'w-full',
        2:'w-1/2',
        3:'w-1/3',
        4:'w-1/4',
        5:'w-1/5',
        6:'w-1/6'
    }[menuItems.length]

    const linkClassNames = {
        container:{
            base:`bg-gradient-to-b from-sun via-sun to-sky relative`,
            hover:`hover:bg-transparent hover:bg-gradient-to-t hover:to-lightblue hover:via-lightblue2 hover:from-lightblue3`,
            dark:`dark:from-transparent dark:via-transparent dark:to-transparent dark:bg-island`,
            darkHover:`dark:hover:bg-transparent dark:hover:bg-gradient-to-t dark:hover:to-lightblue dark:hover:via-lightblue2 dark:hover:from-lightblue3`
        },
        link:{
            base:`animate-fade-in opacity-0 font-overlock w-full p-2 text-transparent bg-clip-text bg-gradient-to-b from-island via-island to-island flex justify-center items-center text-sm lg:text-base xl:text-lg leading-6 transition scale-100`,
            hover:`hover:scale-110`,
            dark:`dark:from-sun dark:via-sky dark:to-sun block`,
            darkHover:`hover:dark:from-island hover:dark:via-island hover:dark:to-waterDark`
        },
        // container:{
        //     base:`invisible md:visible w-full flex flex-col items-center justify-center relative bg-transparent font-mutka border border-transparent`,
        //     hover:`hover:bg-flax hover:border-red`,
        //     dark:`dark:bg-transparent`,
        //     darkHover:`dark:hover:bg-garnet dark:hover:border-flax `
        // },
        // link:{
        //     base:`font-tinos w-full p-1 lg:p-2 text-black flex justify-center items-center text-lg lg:text-2xl leading-6 transition scale-100 bg-transparent`,
        //     hover:`hover:scale-110 text-caput`,
        //     dark:`dark:text-flax`,
        //     darkHover:`hover:dark:from-transparent hover:dark:via-transparent hover:dark:to-transparent`,
        // },
        background:{
            base:`w-full text-xl absolute top-0 p-1 text-black flex justify-center items-center text-white text-sm 2xl:text-lg leading-6 transition scale-100 blur`,
            hover:`hover:w-full hover:bg-yellow`,
            dark:'dark:hidden'

        },
        hovered:{
            base:'hidden w-full',
            hover:'',
            dark:'dark:hidden',
            darkHover:`dark:hover:absolute dark:hover:inset-0 dark:hover:w-full dark:hover:h-full top-0`
        },
        linkMobile:{
            base:`bg-gradient-to-b from-sun via-sun to-sky dark:from-island dark:via-island dark:to-black p-2 font-overlock w-full text-island dark:text-sun flex justify-center items-center text-2xl leading-6 transition scale-100`,
            hover:`hover:scale-110 text-black`,
            dark:`dark:text-flax `,
            darkHover:`hover:dark:from-transparent hover:dark:via-transparent hover:dark:to-transparent`,
        }
    }
    const containerClasses = Object.values(linkClassNames.container).toString().replaceAll(',', ' ')
    const linkClasses = Object.values(linkClassNames.link).toString().replaceAll(',',' ')
    const linkMobileClasses = Object.values(linkClassNames.linkMobile).toString().replaceAll(',',' ')
    const backgroundClasses = Object.values(linkClassNames.background).toString().replaceAll(',',' ')
    const hoveredClasses = Object.values(linkClassNames.hovered).toString().replaceAll(',',' ')
    const test = menuItems.flatMap((item)=>(
        item.hideSubOnMobile
        ?
        {...item,link:item.mobileLink}
        :
        item?.subMenus
        ?
        item.subMenus.map((sub)=>(
            sub
        ))
        :
        item

    )).sort((a,b)=>(a.name < b.name ? -1 : b.name > a.name ? 1 : 0))
    return(
        <>
        <Popover.Group className="invisible lg:visible flex flex-wrap w-11/12 xl:w-4/5 fixed top-0 z-50 rounded-lg
        ">
            <div className="w-full flex my-2 bg-gradient-to-t from-island via-white to-island p-2 rounded-full elevation-20">
                <div className="overflow-hidden rounded-full w-full flex bg-gradient-to-tr from-flax via-white to-flax dark:from-black dark:via-red dark:to-black border border-red dark:border-flax">
            {
                menuItems.map(({name, subMenus, link})=>

                        <div className="pr-1 last:pr-0 flex w-full justify-between items-center overflow-hidden" key={name}>
                            {
                                link
                                ?
                                <div className={`${widthClass} w-full text-center font-black text-black`}>
                                    <div className={containerClasses}>
                                        <div className={`${backgroundClasses} dark:text-yellow dark:opacity-80`}>{name}</div>
                                        <Link className={linkClasses} href={link}>{name}</Link>
                                    </div>
                                </div>
                                :
                                <SubMenu name={name} subMenus={subMenus} className={"w-full"} classNames={{container:containerClasses,link:linkClasses, background:backgroundClasses}}/>
                            }
                        </div>
                    )
                }
                </div>
                  
            </div>
      </Popover.Group>
      <div className="visible fixed top-1 lg:top-[80px] xl:top-1 left-1 z-50">
        <ThemeSwitcher/>
      </div>
      <Popover className="w-full">
      <Popover.Button aria-label='open mobile menu'> 
        <div className="visible lg:hidden z-50 fixed flex justify-center top-1 right-1 animate-fade-in animate-del-1200 animate-dur-300 elevation-20">
            <div className="w-[60px] p-1 border border-island rounded-md bg-gradient-to-b from-lightblue via-sun to-waterDark">
                <div className="p-1 border border-island rounded-md bg-gradient-to-b from-lightblue via-lightblue2 to-lightblue3 dark:from-caput dark:via-red dark:to-caput">
                <svg className="w-full" viewBox="0 0 51 51">
                    <path className="fill-red dark:fill-flax" d="M 5 10 L 46 10, 46 17, 5 17Z"/>
                    <path className="fill-red dark:fill-flax" d="M 5 22 L 46 22, 46 29, 5 29"/>
                    <path className="fill-red dark:fill-flax" d="M 5 34 L 46 34, 46 41, 5 41Z"/>
                </svg>
                </div>
            </div>
        </div>
      </Popover.Button>  
      <Popover.Panel className="mt-[40px] z-50 w-full fixed p-2 border border-island rounded-md bg-gradient-to-b from-sun via-sun to-waterDark elevation-20">
        <div className="w-full border border-island rounded-md bg-gradient-to-tl from-island via-silver to-island dark:bg-silver dark:from-caput dark:to-caput dark:via-red py-5">
            {
                 test.map(({name, link})=>              
                 <div className="flex w-full justify-between items-center overflow-hidden" key={name}>
                    <div className={`${widthClass} w-full text-center font-black`}>
                        <div className="border border-lightblue dark:border-lightblue">
                            <Link className={linkMobileClasses} href={link}>{name}</Link>
                        </div>
                    </div>
                 </div>
             )
            }
        </div>
      </Popover.Panel>
      </Popover>
      </>
    )
}

export default NavMenu
