import RestaurantIcon from "./RestaurantIcon"
import IndulgeIcon from "./IndulgeIcon"
import PetIcon from './PetIcon'
import ShoppingIcon from "./ShoppingIcon"
import CarIcon from './CarIcon'
import FishingIcon from "./FishingIcon"
import CoffeeIcon from './CoffeeIcon'
import AttractionsIcon from "./AttractionsIcon"
import RecreationIcon from "./RecreationIcon"
import GasIcon from './GasIcon'
import BaconIcon from './BaconIcon'
import LunchIcon from './LunchIcon'
import DinnerIcon from './DinnerIcon'
import BeerIcon from './BeerIcon'
import WineIcon from './WineIcon'
import LiquorIcon from './LiquorIcon'
import MicrowaveIcon from './MicrowaveIcon'
import FridgeIcon from './FridgeIcon'
import BathtubIcon from './BathtubIcon'
import TVIcon from './TVIcon'
import SecurityIcon from './SecurityIcon'
import WiFiIcon from './WiFiIcon'
import TreadmillIcon from './TreadmillIcon'
import VacuumIcon from './VacuumIcon'
import LaundryIcon from './LaundryIcon'
import FrontDeskIcon from './FrontDeskIcon'
import ACIcon from './ACIcon'

const Icon = ({type='restaurant', className, pathClassName}) => (
{
        'restaurant':<RestaurantIcon className={className} pathClassName={pathClassName}/>,
        'indulge':<IndulgeIcon className={className} pathClassName={pathClassName}/>,
        'pet':<PetIcon className={className} pathClassName={pathClassName}/>,
        'shopping':<ShoppingIcon className={className} pathClassName={pathClassName}/>,
        'car':<CarIcon className={className} pathClassName={pathClassName}/>,
        'fishing':<FishingIcon className={className} pathClassName={pathClassName}/>,
        'coffee':<CoffeeIcon className={className} pathClassName={pathClassName}/>,
        'attractions':<AttractionsIcon className={className} pathClassName={pathClassName}/>,
        'recreation':<RecreationIcon className={className} pathClassName={pathClassName}/>,
        'gas':<GasIcon className={className} pathClassName={pathClassName}/>,
        'bacon':<BaconIcon className={className} pathClassName={pathClassName}/>,
        'lunch':<LunchIcon className={className} pathClassName={pathClassName}/>,
        'dinner':<DinnerIcon className={className} pathClassName={pathClassName}/>,
        'beer':<BeerIcon className={className} pathClassName={pathClassName}/>,
        'wine':<WineIcon className={className} pathClassName={pathClassName}/>,
        'liquor':<LiquorIcon className={className} pathClassName={pathClassName}/>,
        'microwave':<MicrowaveIcon className={className} pathClassName={pathClassName}/>,
        'fridge':<FridgeIcon className={className} pathClassName={pathClassName}/>,
        'tub':<BathtubIcon className={className} pathClassName={pathClassName}/>,
        'tv':<TVIcon className={className} pathClassName={pathClassName}/>,
        'security':<SecurityIcon className={className} pathClassName={pathClassName}/>,
        'wifi':<WiFiIcon className={className} pathClassName={pathClassName}/>,
        'treadmill':<TreadmillIcon className={className} pathClassName={pathClassName}/>,
        'vacuum':<VacuumIcon className={className} pathClassName={pathClassName}/>,
        'laundry':<LaundryIcon className={className} pathClassName={pathClassName}/>,
        'fd':<FrontDeskIcon className={className} pathClassName={pathClassName}/>,
        'ac':<ACIcon className={className} pathClassName={pathClassName}/>
    }[type]
)

export default Icon