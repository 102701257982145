import React, { useRef, useEffect } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Image from 'next/image'
import { Logo } from './'
// import './MapComponent.css';

const Map = ({ className }) => {

  return (
    <div className="map-component bg-sun z-30 w-full h-full relative">
            <Image
            src="https://sunset-inn-dev.s3.ca-central-1.amazonaws.com/map/sunset_inn_map.webp"
            alt="map showing sunset inn and surrounding amenities, with walking and driving times"
            fill
            style={{ objectFit: 'cover' }}
            />

    </div>
  );
};

export default Map;
