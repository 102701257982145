import React, { createElement } from 'react'

const WBG = ({type, className, classNames, children}) => {
    const el = createElement(
        type,
        { className:`${ classNames.front ? `${classNames.front} ` : ''}relative` },
        children
    )
    const bg = createElement(
        type,
        { className:`${ classNames.back ? `${classNames.back} ` : ''}absolute top-0 left-0 right-0` },
        children
    )
    return(
        <div className={`${className ? `${className} ` : ''}relative pointer-event-none`}>
            {
                bg
            }
            {
                el
            }
        </div>
    )
}

export default WBG