const Microphone = ({className, pathClassName={starTop:'fill-sun', starLeft:'fill-sky', starRight:'fill-sky', squiggle:'fill-lightblue', swoop:'fill-avocado',microphone:'fill-honeydew stroke-2 stroke-yellow'}}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    className={className}
  viewBox="0 0 2061 1934">
<path id="star-top"
     className={pathClassName.starTop} d="M 1137.59,348.78 C 1135.05,348.78 1132.51,348.00 1130.36,346.43 1130.36,346.43 1032.28,275.15 1032.28,275.15 1032.28,275.15 934.18,346.43 934.18,346.43 929.87,349.56 924.03,349.56 919.72,346.43 915.40,343.30 913.59,337.73 915.24,332.67 915.24,332.67 952.71,217.35 952.71,217.35 952.71,217.35 854.62,146.09 854.62,146.09 850.31,142.96 848.50,137.39 850.15,132.33 851.80,127.26 856.52,123.83 861.85,123.83 861.85,123.83 983.11,123.83 983.11,123.83 983.11,123.83 1020.57,8.51 1020.57,8.51 1022.22,3.44 1026.94,0.01 1032.28,0.01 1037.61,0.01 1042.33,3.44 1043.98,8.51 1043.98,8.51 1081.44,123.83 1081.44,123.83 1081.44,123.83 1202.70,123.83 1202.70,123.83 1208.03,123.83 1212.75,127.26 1214.40,132.33 1216.05,137.40 1214.24,142.96 1209.93,146.09 1209.93,146.09 1111.83,217.35 1111.83,217.35 1111.83,217.35 1149.30,332.67 1149.30,332.67 1150.95,337.74 1149.14,343.30 1144.83,346.43 1142.68,348.00 1140.13,348.78 1137.59,348.78 1137.59,348.78 1137.59,348.78 1137.59,348.78 Z M 899.72,148.43 C 899.72,148.43 974.41,202.69 974.41,202.69 978.73,205.82 980.54,211.37 978.89,216.44 978.89,216.44 950.36,304.26 950.36,304.26 950.36,304.26 1025.05,249.98 1025.05,249.98 1029.36,246.85 1035.21,246.85 1039.52,249.98 1039.52,249.98 1114.21,304.26 1114.21,304.26 1114.21,304.26 1085.68,216.44 1085.68,216.44 1084.03,211.37 1085.52,205.82 1090.16,202.69 1090.16,202.69 1164.85,148.43 1164.85,148.43 1164.85,148.43 1072.52,148.43 1072.52,148.43 1067.19,148.43 1062.47,145.00 1060.82,139.92 1060.82,139.92 1032.30,52.11 1032.30,52.11 1032.30,52.11 1003.76,139.92 1003.76,139.92 1002.11,145.00 997.39,148.43 992.06,148.43 992.06,148.43 899.74,148.43 899.74,148.43 899.74,148.43 899.72,148.43 899.72,148.43 Z" />
<path id="star-left"
     className={pathClassName.starLeft} d="M 686.26,589.67 C 683.72,589.67 681.18,588.89 679.03,587.32 679.03,587.32 580.94,516.04 580.94,516.04 580.94,516.04 482.85,587.32 482.85,587.32 478.54,590.45 472.69,590.45 468.38,587.32 464.07,584.18 462.26,578.63 463.91,573.56 463.91,573.56 501.38,458.24 501.38,458.24 501.38,458.24 403.29,386.98 403.29,386.98 398.98,383.85 397.17,378.29 398.82,373.22 400.47,368.15 405.19,364.72 410.52,364.72 410.52,364.72 531.78,364.72 531.78,364.72 531.78,364.72 569.24,249.40 569.24,249.40 570.89,244.33 575.61,240.90 580.94,240.90 586.28,240.90 591.00,244.33 592.65,249.40 592.65,249.40 630.11,364.72 630.11,364.72 630.11,364.72 751.36,364.72 751.36,364.72 756.70,364.72 761.42,368.15 763.07,373.22 764.72,378.29 762.91,383.85 758.60,386.98 758.60,386.98 660.51,458.24 660.51,458.24 660.51,458.24 697.98,573.56 697.98,573.56 699.63,578.63 697.82,584.18 693.50,587.32 691.35,588.89 688.81,589.67 686.27,589.67 686.27,589.67 686.26,589.67 686.26,589.67 Z M 448.40,389.32 C 448.40,389.32 523.09,443.58 523.09,443.58 527.40,446.71 529.22,452.26 527.56,457.33 527.56,457.33 499.04,545.15 499.04,545.15 499.04,545.15 573.73,490.87 573.73,490.87 578.04,487.74 583.88,487.74 588.20,490.87 588.20,490.87 662.89,545.15 662.89,545.15 662.89,545.15 634.36,457.33 634.36,457.33 632.71,452.26 634.20,446.71 638.83,443.58 638.83,443.58 713.53,389.32 713.53,389.32 713.53,389.32 621.21,389.32 621.21,389.32 615.88,389.32 611.16,385.89 609.51,380.81 609.51,380.81 580.98,293.00 580.98,293.00 580.98,293.00 552.45,380.81 552.45,380.81 550.80,385.89 546.08,389.32 540.75,389.32 540.75,389.32 448.43,389.32 448.43,389.32 448.43,389.32 448.40,389.32 448.40,389.32 Z" />
<path id="star-right"
     className={pathClassName.starRight} d="M 1588.93,589.67 C 1586.38,589.67 1583.85,588.89 1581.69,587.32 1581.69,587.32 1483.61,516.04 1483.61,516.04 1483.61,516.04 1385.52,587.32 1385.52,587.32 1381.20,590.45 1375.37,590.45 1371.06,587.32 1366.75,584.18 1364.95,578.63 1366.59,573.56 1366.59,573.56 1404.05,458.24 1404.05,458.24 1404.05,458.24 1305.95,386.98 1305.95,386.98 1301.64,383.85 1299.84,378.29 1301.48,373.22 1303.13,368.15 1307.85,364.72 1313.19,364.72 1313.19,364.72 1434.44,364.72 1434.44,364.72 1434.44,364.72 1471.90,249.40 1471.90,249.40 1473.55,244.33 1478.28,240.90 1483.61,240.90 1488.94,240.90 1493.66,244.33 1495.31,249.40 1495.31,249.40 1532.78,364.72 1532.78,364.72 1532.78,364.72 1654.03,364.72 1654.03,364.72 1659.36,364.72 1664.08,368.15 1665.73,373.22 1667.38,378.29 1665.57,383.85 1661.26,386.98 1661.26,386.98 1563.16,458.24 1563.16,458.24 1563.16,458.24 1600.63,573.56 1600.63,573.56 1602.28,578.63 1600.47,584.18 1596.16,587.32 1594.00,588.89 1591.47,589.67 1588.93,589.67 1588.93,589.67 1588.93,589.67 1588.93,589.67 Z M 1483.60,488.53 C 1486.14,488.53 1488.67,489.31 1490.83,490.88 1490.83,490.88 1565.52,545.16 1565.52,545.16 1565.52,545.16 1536.99,457.34 1536.99,457.34 1535.34,452.27 1536.83,446.72 1541.46,443.59 1541.46,443.59 1616.15,389.33 1616.15,389.33 1616.15,389.33 1523.82,389.33 1523.82,389.33 1518.49,389.33 1513.77,385.90 1512.12,380.82 1512.12,380.82 1483.60,293.01 1483.60,293.01 1483.60,293.01 1455.07,380.82 1455.07,380.82 1453.42,385.90 1448.70,389.33 1443.37,389.33 1443.37,389.33 1351.05,389.33 1351.05,389.33 1351.05,389.33 1425.75,443.59 1425.75,443.59 1430.06,446.72 1431.86,452.27 1430.22,457.34 1430.22,457.34 1401.68,545.16 1401.68,545.16 1401.68,545.16 1476.37,490.88 1476.37,490.88 1478.53,489.31 1481.06,488.53 1483.60,488.53 1483.60,488.53 1483.60,488.53 1483.60,488.53 Z" />
<path id="squiggle-left1"
     className={pathClassName.squiggle} d="M 12.31,873.36 C 8.76,873.36 5.24,871.83 2.81,868.88 -1.51,863.62 -0.75,855.87 4.49,851.55 4.49,851.55 123.26,753.79 123.26,753.79 179.17,707.77 259.54,708.71 314.36,756.01 314.36,756.01 331.80,771.06 331.80,771.06 380.27,812.89 451.73,811.08 498.02,766.85 553.52,713.82 639.19,711.65 697.32,761.80 697.32,761.80 702.68,766.43 702.68,766.43 707.83,770.87 708.40,778.63 703.96,783.78 699.52,788.93 691.75,789.51 686.60,785.06 686.60,785.06 681.24,780.43 681.24,780.43 632.77,738.60 561.32,740.41 515.02,784.64 459.51,837.68 373.84,839.84 315.73,789.70 315.73,789.70 298.28,774.64 298.28,774.64 252.56,735.18 185.53,734.40 138.89,772.78 138.89,772.78 20.12,870.55 20.12,870.55 17.83,872.43 15.06,873.35 12.31,873.35 12.31,873.35 12.31,873.36 12.31,873.36 Z" />
<path id="squiggle-left2"
     className={pathClassName.squiggle}
     d="M 12.31,984.97 C 8.76,984.97 5.24,983.44 2.81,980.49 -1.51,975.25 -0.75,967.49 4.49,963.18 4.49,963.18 123.26,865.41 123.26,865.41 179.17,819.39 259.54,820.33 314.36,867.64 314.36,867.64 331.80,882.69 331.80,882.69 380.27,924.52 451.73,922.70 498.02,878.48 553.52,825.44 639.19,823.28 697.32,873.43 697.32,873.43 702.68,878.06 702.68,878.06 707.83,882.50 708.40,890.26 703.96,895.41 699.52,900.55 691.75,901.13 686.60,896.69 686.60,896.69 681.24,892.06 681.24,892.06 632.76,850.23 561.31,852.04 515.02,896.27 459.52,949.31 373.84,951.47 315.73,901.32 315.73,901.32 298.28,886.27 298.28,886.27 252.57,846.82 185.53,846.03 138.90,884.42 138.90,884.42 20.13,982.18 20.13,982.18 17.84,984.06 15.07,984.98 12.32,984.98 12.32,984.98 12.31,984.97 12.31,984.97 Z" />
<path id="squiggle-left3"
     className={pathClassName.squiggle}
     d="M 12.31,1113.74 C 8.76,1113.74 5.24,1112.21 2.81,1109.26 -1.51,1104.00 -0.75,1096.25 4.49,1091.93 4.49,1091.93 123.26,994.17 123.26,994.17 179.17,948.15 259.54,949.09 314.36,996.39 314.36,996.39 331.80,1011.44 331.80,1011.44 380.27,1053.27 451.73,1051.46 498.02,1007.23 553.52,954.21 639.19,952.04 697.32,1002.18 697.32,1002.18 702.68,1006.81 702.68,1006.81 707.83,1011.25 708.40,1019.01 703.96,1024.16 699.52,1029.31 691.75,1029.89 686.60,1025.44 686.60,1025.44 681.24,1020.81 681.24,1020.81 632.77,978.98 561.31,980.79 515.02,1025.02 459.52,1078.06 373.84,1080.22 315.73,1030.08 315.73,1030.08 298.28,1015.02 298.28,1015.02 252.57,975.56 185.53,974.78 138.90,1013.16 138.90,1013.16 20.13,1110.93 20.13,1110.93 17.84,1112.81 15.07,1113.73 12.32,1113.73 12.32,1113.73 12.31,1113.74 12.31,1113.74 Z" />
<path id="squiggle-right1"
     className={pathClassName.squiggle}
     d="M 2048.69,873.36 C 2045.94,873.36 2043.17,872.44 2040.88,870.56 2040.88,870.56 1922.11,772.79 1922.11,772.79 1875.48,734.41 1808.45,735.19 1762.72,774.65 1762.72,774.65 1745.27,789.71 1745.27,789.71 1687.15,839.86 1601.48,837.69 1545.98,784.65 1499.69,740.43 1428.23,738.62 1379.76,780.44 1379.76,780.44 1374.40,785.06 1374.40,785.06 1369.26,789.51 1361.48,788.94 1357.04,783.78 1352.60,778.64 1353.18,770.87 1358.32,766.43 1358.32,766.43 1363.68,761.80 1363.68,761.80 1421.81,711.65 1507.47,713.82 1562.98,766.85 1609.27,811.08 1680.73,812.89 1729.20,771.06 1729.20,771.06 1746.64,756.01 1746.64,756.01 1801.47,708.70 1881.84,707.77 1937.74,753.79 1937.74,753.79 2056.51,851.55 2056.51,851.55 2061.76,855.87 2062.51,863.62 2058.19,868.88 2055.76,871.83 2052.24,873.36 2048.68,873.36 2048.68,873.36 2048.69,873.36 2048.69,873.36 Z" />
<path id="squiggle-right2"
     className={pathClassName.squiggle}
     d="M 2048.69,984.97 C 2045.94,984.97 2043.17,984.05 2040.88,982.17 2040.88,982.17 1922.11,884.41 1922.11,884.41 1875.48,846.03 1808.45,846.80 1762.72,886.26 1762.72,886.26 1745.27,901.31 1745.27,901.31 1687.15,951.47 1601.48,949.29 1545.98,896.26 1499.69,852.03 1428.23,850.23 1379.76,892.05 1379.76,892.05 1374.40,896.67 1374.40,896.67 1369.26,901.11 1361.48,900.54 1357.04,895.39 1352.60,890.25 1353.18,882.48 1358.32,878.04 1358.32,878.04 1363.68,873.41 1363.68,873.41 1421.81,823.25 1507.47,825.42 1562.98,878.45 1609.27,922.68 1680.73,924.49 1729.20,882.66 1729.20,882.66 1746.64,867.61 1746.64,867.61 1801.47,820.29 1881.84,819.36 1937.74,865.38 1937.74,865.38 2056.51,963.15 2056.51,963.15 2061.76,967.47 2062.51,975.22 2058.19,980.46 2055.76,983.42 2052.24,984.94 2048.68,984.94 2048.68,984.94 2048.69,984.97 2048.69,984.97 Z" />
<path id="squiggle-right3"
     className={pathClassName.squiggle}
     d="M 2048.69,1113.74 C 2045.94,1113.74 2043.17,1112.82 2040.88,1110.94 2040.88,1110.94 1922.11,1013.17 1922.11,1013.17 1875.48,974.79 1808.45,975.57 1762.72,1015.03 1762.72,1015.03 1745.27,1030.09 1745.27,1030.09 1687.15,1080.23 1601.48,1078.06 1545.98,1025.03 1499.69,980.80 1428.23,978.99 1379.76,1020.82 1379.76,1020.82 1374.40,1025.44 1374.40,1025.44 1369.26,1029.88 1361.48,1029.32 1357.04,1024.16 1352.60,1019.02 1353.18,1011.25 1358.32,1006.81 1358.32,1006.81 1363.68,1002.18 1363.68,1002.18 1421.80,952.03 1507.47,954.20 1562.98,1007.22 1609.27,1051.45 1680.73,1053.26 1729.20,1011.43 1729.20,1011.43 1746.64,996.38 1746.64,996.38 1801.47,949.07 1881.84,948.14 1937.74,994.16 1937.74,994.16 2056.51,1091.92 2056.51,1091.92 2061.76,1096.24 2062.51,1103.99 2058.19,1109.25 2055.76,1112.20 2052.24,1113.73 2048.68,1113.73 2048.68,1113.73 2048.69,1113.74 2048.69,1113.74 Z" />
<path id="swoop-left1"
     className={pathClassName.swoop}
     d="M 835.91,1528.42 C 834.57,1528.42 833.22,1528.20 831.88,1527.74 653.67,1465.98 511.60,1322.31 451.85,1143.39 449.70,1136.95 453.18,1129.98 459.62,1127.83 466.06,1125.68 473.03,1129.16 475.19,1135.60 532.53,1307.31 668.88,1445.22 839.92,1504.48 846.34,1506.71 849.74,1513.72 847.52,1520.14 845.76,1525.23 840.99,1528.42 835.90,1528.42 835.90,1528.42 835.91,1528.42 835.91,1528.42 Z" />
<path id="swoop-left2"
     className={pathClassName.swoop}
     d="M 1228.55,1528.48 C 1223.45,1528.48 1218.69,1525.29 1216.93,1520.20 1214.71,1513.78 1218.11,1506.76 1224.53,1504.54 1393.11,1446.15 1528.67,1310.61 1587.15,1141.96 1589.38,1135.54 1596.38,1132.14 1602.81,1134.37 1609.22,1136.60 1612.62,1143.60 1610.40,1150.03 1549.48,1325.73 1408.23,1466.95 1232.58,1527.79 1231.25,1527.33 1229.89,1527.11 1228.56,1527.11 1228.56,1527.11 1228.55,1528.48 1228.55,1528.48 Z" />
<path id="swoop-left3"
     className={pathClassName.swoop}
     d="M 828.54,1682.91 C 827.43,1682.91 826.31,1682.76 825.20,1682.45 691.72,1644.81 570.85,1571.02 475.67,1469.06 380.07,1366.67 314.69,1240.41 286.57,1103.90 285.20,1097.25 289.48,1090.74 296.14,1089.37 302.81,1088.00 309.31,1092.28 310.68,1098.94 366.36,1369.30 566.07,1583.80 831.88,1658.76 838.42,1660.61 842.22,1667.40 840.38,1673.95 838.85,1679.37 833.92,1682.92 828.55,1682.92 828.55,1682.92 828.54,1682.91 828.54,1682.91 Z" />
<path id="swoop-right1"
     className={pathClassName.swoop}
     d="M 1236.00,1682.84 C 1230.63,1682.84 1225.70,1679.29 1224.17,1673.87 1222.33,1667.32 1226.14,1660.53 1232.67,1658.68 1500.34,1583.30 1706.02,1358.07 1756.68,1084.87 1757.92,1078.19 1764.32,1073.78 1771.01,1075.02 1777.69,1076.26 1782.10,1082.68 1780.86,1089.36 1754.96,1229.07 1690.26,1358.56 1593.78,1463.82 1497.82,1568.52 1375.25,1644.10 1239.33,1682.37 1238.21,1682.06 1237.09,1681.91 1235.99,1681.91 1235.99,1681.91 1236.00,1682.84 1236.00,1682.84 Z" />
<path id="swoop-right2"
     className={pathClassName.swoop}
     d="M 738.95,1810.84 C 737.62,1810.84 736.26,1810.62 734.93,1810.16 420.64,1701.57 190.75,1430.80 134.97,1103.48 133.83,1096.78 138.33,1090.43 145.04,1089.29 151.74,1088.16 158.09,1092.66 159.23,1099.35 213.49,1417.80 437.17,1681.26 742.96,1786.90 749.38,1789.12 752.80,1796.13 750.57,1802.55 748.81,1807.64 744.05,1810.84 738.94,1810.84 738.94,1810.84 738.95,1810.84 738.95,1810.84 Z" />
<path id="swoop-right3"
     className={pathClassName.swoop}
     d="M 1325.58,1810.78 C 1320.48,1810.78 1315.71,1807.58 1313.95,1802.49 1311.73,1796.07 1315.14,1789.05 1321.56,1786.84 1621.89,1683.15 1844.64,1424.96 1902.88,1113.06 1904.13,1106.37 1910.52,1101.95 1917.23,1103.22 1923.92,1104.47 1928.32,1110.90 1927.07,1117.58 1867.20,1438.17 1638.25,1703.52 1329.59,1810.11 1328.26,1809.65 1326.90,1809.43 1325.57,1809.43 1325.57,1809.43 1325.58,1810.78 1325.58,1810.78 Z" />
<path id="mic-head"
     className={pathClassName.microphone}
     d="M 1125.25,1447.32 C 1125.25,1447.32 939.29,1447.32 939.29,1447.32 853.89,1447.32 784.42,1377.84 784.42,1292.44 784.42,1292.44 784.42,606.63 784.42,606.63 784.42,521.23 853.89,451.75 939.29,451.75 939.29,451.75 1125.25,451.75 1125.25,451.75 1210.65,451.75 1280.12,521.23 1280.12,606.63 1280.12,606.63 1280.12,1292.43 1280.12,1292.43 1280.12,1377.83 1210.65,1447.31 1125.25,1447.31 1125.25,1447.31 1125.25,1447.32 1125.25,1447.32 Z M 939.29,476.35 C 867.46,476.35 809.03,534.79 809.03,606.62 809.03,606.62 809.03,1292.42 809.03,1292.42 809.03,1364.25 867.47,1422.69 939.29,1422.69 939.29,1422.69 1125.25,1422.69 1125.25,1422.69 1197.07,1422.69 1255.52,1364.25 1255.52,1292.42 1255.52,1292.42 1255.52,606.63 1255.52,606.63 1255.52,534.79 1197.07,476.36 1125.25,476.36 1125.25,476.36 939.29,476.36 939.29,476.36 939.29,476.36 939.29,476.35 939.29,476.35 Z" />
<path id="mic-line1"
     className={pathClassName.microphone}
     d="M 1267.79,712.32 C 1267.79,712.32 796.75,712.32 796.75,712.32 789.96,712.32 784.45,706.81 784.45,700.01 784.45,693.22 789.96,687.71 796.75,687.71 796.75,687.71 1267.79,687.71 1267.79,687.71 1274.59,687.71 1280.09,693.22 1280.09,700.01 1280.09,706.81 1274.58,712.32 1267.79,712.32 Z" />
<path id="mic-line2"
     className={pathClassName.microphone}
     d="M 1267.79,803.18 C 1267.79,803.18 796.75,803.18 796.75,803.18 789.96,803.18 784.45,797.67 784.45,790.88 784.45,784.08 789.96,778.57 796.75,778.57 796.75,778.57 1267.79,778.57 1267.79,778.57 1274.59,778.57 1280.09,784.08 1280.09,790.88 1280.09,797.67 1274.58,803.18 1267.79,803.18 Z" />
<path id="mic-line3"
     className={pathClassName.microphone}
     d="M 1267.79,894.11 C 1267.79,894.11 796.75,894.11 796.75,894.11 789.96,894.11 784.45,888.60 784.45,881.81 784.45,875.02 789.96,869.51 796.75,869.51 796.75,869.51 1267.79,869.51 1267.79,869.51 1274.59,869.51 1280.09,875.02 1280.09,881.81 1280.09,888.60 1274.58,894.11 1267.79,894.11 Z" />
<path id="mic-line4"
     className={pathClassName.microphone}
     d="M 1267.79,984.97 C 1267.79,984.97 796.75,984.97 796.75,984.97 789.96,984.97 784.45,979.46 784.45,972.67 784.45,965.88 789.96,960.37 796.75,960.37 796.75,960.37 1267.79,960.37 1267.79,960.37 1274.59,960.37 1280.09,965.88 1280.09,972.67 1280.09,979.46 1274.58,984.97 1267.79,984.97 Z" />
<path id="mic-line5"
     className={pathClassName.microphone}
     d="M 1267.79,1075.85 C 1267.79,1075.85 796.75,1075.85 796.75,1075.85 789.96,1075.85 784.45,1070.34 784.45,1063.54 784.45,1056.75 789.96,1051.24 796.75,1051.24 796.75,1051.24 1267.79,1051.24 1267.79,1051.24 1274.59,1051.24 1280.09,1056.75 1280.09,1063.54 1280.09,1070.34 1274.58,1075.85 1267.79,1075.85 Z" />
<path id="base-top"
     className={pathClassName.microphone}
     d="M 1105.81,1499.93 C 1105.81,1499.93 958.74,1499.93 958.74,1499.93 951.95,1499.93 946.44,1494.42 946.44,1487.63 946.44,1480.84 951.95,1475.33 958.74,1475.33 958.74,1475.33 1105.81,1475.33 1105.81,1475.33 1112.60,1475.33 1118.11,1480.84 1118.11,1487.63 1118.11,1494.42 1112.60,1499.93 1105.81,1499.93 Z" />
<path id="base-top2"
     className={pathClassName.microphone}
     d="M 1081.89,1542.98 C 1081.89,1542.98 982.66,1542.98 982.66,1542.98 975.87,1542.98 970.35,1537.47 970.35,1530.68 970.35,1523.89 975.87,1518.38 982.66,1518.38 982.66,1518.38 1081.89,1518.38 1081.89,1518.38 1088.69,1518.38 1094.20,1523.89 1094.20,1530.68 1094.20,1537.47 1088.69,1542.98 1081.89,1542.98 Z" />
<path id="base-stem"
     className={pathClassName.microphone}
     d="M 1032.28,1837.14 C 1025.48,1837.14 1019.97,1831.62 1019.97,1824.83 1019.97,1824.83 1019.97,1530.68 1019.97,1530.68 1019.97,1523.88 1025.48,1518.38 1032.28,1518.38 1039.07,1518.38 1044.58,1523.89 1044.58,1530.68 1044.58,1530.68 1044.58,1824.83 1044.58,1824.83 1044.58,1831.62 1039.07,1837.14 1032.28,1837.14 Z" />
<path id="base-bottom"
     className={pathClassName.microphone}
     d="M 1251.67,1934.00 C 1244.87,1934.00 1239.37,1928.49 1239.37,1921.70 1239.37,1880.87 1156.15,1837.15 1032.27,1837.15 920.78,1837.15 842.23,1872.54 827.62,1909.39 827.62,1909.39 1219.39,1909.39 1219.39,1909.39 1226.19,1909.39 1231.69,1914.91 1231.69,1921.70 1231.69,1928.49 1226.18,1934.00 1219.39,1934.00 1219.39,1934.00 812.86,1934.00 812.86,1934.00 806.06,1934.00 800.55,1928.49 800.55,1921.70 800.55,1860.48 902.33,1812.54 1032.27,1812.54 1162.20,1812.54 1263.98,1860.49 1263.98,1921.70 1263.98,1928.50 1258.47,1934.00 1251.67,1934.00 1251.67,1934.00 1251.67,1934.00 1251.67,1934.00 Z" />
</svg>
)

export default Microphone