import React from 'react'

const sunIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 25 24"
      className="w-[25px] p-1"
    >
      <g
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_192_823)"
      >
        <path 
          className="stroke-island dark:stroke-sun fill-island dark:fill-sun" 
          d="M12.5 17a5 5 0 100-10 5 5 0 000 10zM12.5 1v2M12.5 21v2M4.72 4.22l1.42 1.42M18.86 18.36l1.42 1.42M1.5 12h2M21.5 12h2M4.72 19.78l1.42-1.42M18.86 5.64l1.42-1.42"></path>
      </g>
    </svg>
  );
  
  const moonIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 21 20"
      className="w-[25px] p-1"
    >
      <path
        className="stroke-sun dark:stroke-island fill-sun dark:fill-island"
        d="M19.5 10.79A9 9 0 119.71 1a7 7 0 009.79 9.79v0z"
      ></path>
    </svg>
  );
  
  const ThemeSwitcher = () => {
    return (
      <div className="flex h-[25px] justify-center dark:bg-gray-900 rounded-3xl z-30">
        <button
          name="dark-mode-button"
          type="button"
          aria-label="Use Dark Mode"
          onClick={() => {
            document.documentElement.classList.add('dark');
            localStorage.setItem('theme', 'dark');
          }}
          className="flex items-center h-full border border-c7 bg-island dark:bg-sun dark:border-c9 dark:bg-c3 rounded-3xl flex justify-center align-center transition p-3"
        >
          {moonIcon}
        </button>
  
        <button
          name="light-mode-button"
          type="button"
          aria-label="Use Light Mode"
          onClick={() => {
            document.documentElement.classList.remove('dark');
            localStorage.setItem('theme', 'light');
          }}
          className="flex items-center h-full border border-c7 bg-sun dark:bg-island dark:border-c9 dark:bg-c1 bg-yellow rounded-3xl flex justify-center align-center transition p-3"
        >
          {sunIcon}
        </button>
      </div>
    );
  };

  
  export default ThemeSwitcher