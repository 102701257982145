import React, { useState, useEffect } from 'react';

const AnimatedSprite = ({
  spriteSheet,
  frameWidth,
  frameHeight,
  frameCount,
  animationSpeed,
}) => {
  const [currentFrame, setCurrentFrame] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentFrame((prevFrame) => (prevFrame + 1) % frameCount);
    }, animationSpeed);

    return () => {
      clearInterval(interval);
    };
  }, [frameCount, animationSpeed]);

  const backgroundPositionX = -currentFrame * frameWidth;

  return (
    <div
      className="w-[103px] h-full inline-block"
      style={{
        width: frameWidth,
        height: frameHeight,
        backgroundImage: `url(${spriteSheet})`,
        backgroundPositionX,
        backgroundPositionY: 0,
        backgroundRepeat: 'no-repeat',
        opacity:0.75
      }}
    />
  );
};

export default AnimatedSprite;
