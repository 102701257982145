import { useRef } from "react";
import Link from 'next/link';
import { useDateFormatter } from "@react-aria/i18n";
import { useRangeCalendarState } from "react-stately";
import { useRangeCalendar } from "@react-aria/calendar";
import { useLocale } from "@react-aria/i18n";
import { createCalendar } from "@internationalized/date";
import CalendarGrid from "./AriaCalendarGrid";
import CalendarHeader from "./AriaCalendarHeader";
import { Button } from './'

export function RangeCalendar(props) {
  let { locale } = useLocale();
  let state = useRangeCalendarState({
    ...props,
    visibleDuration: { months: 2 },
    locale,
    createCalendar
  });
  let ref = useRef();
  let { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(
    props,
    state,
    ref
  );
  let dateFormatter = useDateFormatter({
    day:"numeric",
    month: "short",
    year: "numeric",
    timeZone: state.timeZone
  });
  const start = dateFormatter.format(
    state.value.start.toDate(state.timeZone)
  ).replace(',','')
  const end = dateFormatter.format(
    state.value.end.toDate(state.timeZone)
  ).replace(',','')
  console.log("CALENDAR PROPS", start)
  return (
    <div {...calendarProps} ref={ref} className="calendar inline-block text-island dark:text-sun z-40">
      <CalendarHeader
        state={state}
        calendarProps={calendarProps}
        prevButtonProps={prevButtonProps}
        nextButtonProps={nextButtonProps}
        order={1}
      />
      <div className="flex flex-col bg-gradient-to-b from-lightblue via-white to-lightblue dark:from-island dark:via-black dark:to-island sm:p-2 elevation-20">
        <div className="flex flex-col sm:flex-row w-full gap-8">
          <CalendarGrid state={state} today={props.today}/>
          <div className="w-full sm:hidden">
          <CalendarHeader
            state={state}
            calendarProps={calendarProps}
            prevButtonProps={prevButtonProps}
            nextButtonProps={nextButtonProps}
            order={2}
          />
          </div>
          <CalendarGrid state={state} offset={{ months: 1 }} today={props.today} /> 
        </div>
        <div className="flex w-full justify-center">
        <Link
            href={`https://www.onressystems.com/reservations/search?property=sunset-inn&checkin=${encodeURI(start)}&checkout=${encodeURI(end)}&rooms=1&numchildren=0&numadults=1&code=&prodList=&lang=en`}
          >
          <Button>Book Now</Button>
        </Link>
        </div>
      </div>
    </div>
  );
}

export default RangeCalendar