import { useState } from 'react'

function BookingForm() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [checkInDate, setCheckInDate] = useState('')
  const [checkOutDate, setCheckOutDate] = useState('')
  const [numberOfGuests, setNumberOfGuests] = useState('')
  const [errors, setErrors] = useState({})

  const handleSubmit = (event) => {
    event.preventDefault()

    // Validate form input values
    const errors = {}
    if (!name) {
      errors.name = 'Name is required'
    }
    if (!email) {
      errors.email = 'Email is required'
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid'
    }
    if (!phone) {
      errors.phone = 'Phone number is required'
    } else if (!/^[0-9]{10}$/.test(phone)) {
      errors.phone = 'Phone number is invalid'
    }
    if (!checkInDate) {
      errors.checkInDate = 'Check-in date is required'
    }
    if (!checkOutDate) {
      errors.checkOutDate = 'Check-out date is required'
    }
    if (!numberOfGuests) {
      errors.numberOfGuests = 'Number of guests is required'
    } else if (!/^[1-9][0-9]*$/.test(numberOfGuests)) {
      errors.numberOfGuests = 'Number of guests must be a positive integer'
    }

    // If there are errors, set the errors state and return
    if (Object.keys(errors).length > 0) {
      setErrors(errors)
      return
    }

    // If there are no errors, handle form submission logic here
    console.log('Form submitted successfully!')
  }

  return (
    <form onSubmit={handleSubmit} className="max-w-6xl p-3 mx-auto flex flex-wrap justify-around items-center">
    <div className="my-4 w-1/3 px-5 flex flex-col">
        <label htmlFor="checkInDate" className="block text-gray-700 font-bold mb-2 w-full text-right">
        Check-in Date
        </label>
        <input
        type="date"
        id="checkInDate"
        value={checkInDate}
        onChange={(e) => setCheckInDate(e.target.value)}
        className={`shadow appearance-none border rounded w-1/2 ml-[50%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
            errors.checkInDate ? 'border-red-500' : ''
        }`}
        placeholder="Select check-in date"
        required
        />
        {errors.checkInDate && (
        <p className="text-red-500 text-xs italic">{errors.checkInDate}</p>
        )}
  </div>
  <div className="my-4 w-1/3 px-5">
    <label htmlFor="checkOutDate" className="w-full block text-gray-700 font-bold mb-2 flex justify-center">
      Check-out Date
    </label>
    <input
      type="date"
      id="checkOutDate"
      value={checkOutDate}
      onChange={(e) => setCheckOutDate(e.target.value)}
      className={`shadow appearance-none border rounded w-1/2 ml-[25%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
        errors.checkOutDate ? 'border-red-500' : ''
      }`}
      placeholder="Select check-out date"
      required
    />
    {errors.checkOutDate && (
      <p className="text-red-500 text-xs italic">{errors.checkOutDate}</p>
    )}
  </div>
  <div className="my-4 w-1/3 px-5">
    <label htmlFor="numberOfGuests" className="block text-gray-700 font-bold mb-2">
      Number of Guests
    </label>
    <input
      type="number"
      id="numberOfGuests"
      value={numberOfGuests}
      onChange={(e) => setNumberOfGuests(e.target.value)}
      className={`shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
        errors.numberOfGuests ? 'border-red-500' : ''
      }`}
      placeholder="Enter number of guests"
      required
    />
    {errors.numberOfGuests && (
      <p className="text-red-500 text-xs italic">{errors.numberOfGuests}</p>
    )}
  </div>
      {/* <div className="my-4 w-1/3 px-5">
        <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
          Name
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
            errors.name ? 'border-red-500' : ''
          }`}
          placeholder="Enter your name"
          required
        />
        {errors.name && (
          <p className="text-red-500 text-xs italic">{errors.name}</p>
        )}
      </div>
      <div className="mb-4 w-1/3 px-5">
        <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
          Email
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
            errors.email ? 'border-red-500' : ''
          }`}
          placeholder="Enter your email"
          required
        />
        {errors.email && (
          <p className="text-red-500 text-xs italic">{errors.email}</p>
        )}
      </div>
      <div className="mb-4 w-1/3 px-5">
      <label htmlFor="phone" className="block text-gray-700 font-bold mb-2">
      Phone
    </label>
    <input
      type="tel"
      id="phone"
      value={phone}
      onChange={(e) => setPhone(e.target.value)}
      className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
        errors.phone ? 'border-red-500' : ''
      }`}
      placeholder="Enter your phone number"
      required
    />
    {errors.phone && (
      <p className="text-red-500 text-xs italic">{errors.phone}</p>
    )}
  </div> */}
  {/* <div className="flex items-center justify-between">
    <button
      type="submit"
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
    >
      Submit
    </button>
  </div> */}
</form>
    )
}

export default BookingForm