import Image from 'next/image'

const Button = ({children, onClick, className, disabled = false}) => (
    <>
        <div className={`absolute inset-0 pointer-events-none${className ? ` ${className}` : ''}`}></div>
        <button disabled={disabled} onClick={onClick} className="relative bg-gradient-to-b from-sky via-waterLight to-waterDark dark:from-island dark:via-island dark:to-black rounded-full mt-2 overflow-hidden border-8 border-island dark:border-black elevation-5">
        <div className="absolute w-full opacity-25 dark:opacity-100 pointer-events-none aspect-[251/102]">
            <Image
                src="/assets/button-bg.webp"
                className="bottom-0"
                alt=""
                fill
                priority
            />
        </div>
        <div className="w-full text-2xl font-black py-6 px-8 relative pointer-events-none">
            <div className="absolute top-[10px] left-[40px] w-[40px] h-[20px] bg-white rounded-full opacity-80 blur"/>
            <div className="relative w-full h-full font-overlock dark:text-sun">
            {children}
            </div>
        </div>
        </button>
  </>
)

export default Button
