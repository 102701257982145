const Number = ({className, pathClassName, imageClassName, firstChildClassName="contents", num}) => {
  const leftPaths = [
    "M 38.69,69.48 C 42.09,69.48 44.73,69.38 46.59,69.16 47.07,70.23 47.31,71.41 47.31,72.69 47.31,75.67 46.01,77.16 43.41,77.16 42.93,77.16 40.93,77.13 37.41,77.08 33.88,77.02 30.60,77.00 27.56,77.00 22.55,77.00 18.98,77.02 16.84,77.08 16.31,75.59 16.05,74.28 16.05,73.16 16.05,70.54 17.86,69.23 21.48,69.23 21.48,69.23 26.12,69.23 26.12,69.23 26.12,68.38 26.15,65.62 26.20,60.95 26.25,56.29 26.28,52.20 26.28,48.69 26.28,42.55 26.20,36.92 26.05,31.80 20.29,32.39 16.95,32.77 16.05,32.92 15.83,31.54 15.72,30.50 15.72,29.80 15.72,26.50 17.08,24.68 19.80,24.36 26.09,23.56 30.78,23.16 33.88,23.16 35.16,23.16 36.29,23.48 37.28,24.12 38.27,24.76 38.77,25.77 38.77,27.16 38.77,27.59 38.71,29.05 38.59,31.56 38.49,34.06 38.38,37.23 38.28,41.05 38.18,44.86 38.12,48.55 38.12,52.12 38.12,52.12 38.12,56.28 38.12,56.28 38.12,62.15 38.31,66.55 38.69,69.48 Z"
  ]
  const rightPaths = [
    "M 88.20,70.36 C 84.39,75.32 79.28,77.80 72.88,77.80 66.48,77.80 61.40,75.34 57.64,70.41 53.88,65.47 52.00,58.84 52.00,50.52 52.00,42.14 53.88,35.49 57.64,30.56 61.40,25.62 66.48,23.16 72.88,23.16 79.28,23.16 84.39,25.62 88.20,30.56 92.02,35.49 93.92,42.14 93.92,50.52 93.92,58.79 92.02,65.40 88.20,70.36 Z M 81.36,51.88 C 81.36,46.54 80.61,41.75 79.11,37.48 77.62,33.21 75.54,31.08 72.88,31.08 70.38,31.08 68.32,32.72 66.72,36.00 65.11,39.28 64.31,43.30 64.31,48.05 64.31,53.81 65.05,58.90 66.55,63.33 68.05,67.75 70.13,69.95 72.80,69.95 75.36,69.95 77.43,68.23 79.00,64.77 80.57,61.30 81.36,57.00 81.36,51.88 Z",
    "M 75.69,69.48 C 79.09,69.48 81.73,69.38 83.59,69.16 84.07,70.23 84.31,71.41 84.31,72.69 84.31,75.67 83.01,77.16 80.41,77.16 79.93,77.16 77.93,77.13 74.41,77.08 70.88,77.02 67.60,77.00 64.56,77.00 59.55,77.00 55.98,77.02 53.84,77.08 53.31,75.59 53.05,74.28 53.05,73.16 53.05,70.54 54.86,69.23 58.48,69.23 58.48,69.23 63.12,69.23 63.12,69.23 63.12,68.38 63.15,65.62 63.20,60.95 63.25,56.29 63.28,52.20 63.28,48.69 63.28,42.55 63.20,36.92 63.05,31.80 57.29,32.39 53.95,32.77 53.05,32.92 52.83,31.54 52.72,30.50 52.72,29.80 52.72,26.50 54.08,24.68 56.80,24.36 63.09,23.56 67.78,23.16 70.88,23.16 72.16,23.16 73.29,23.48 74.28,24.12 75.27,24.76 75.77,25.77 75.77,27.16 75.77,27.59 75.71,29.05 75.59,31.56 75.49,34.06 75.38,37.23 75.28,41.05 75.18,44.86 75.12,48.55 75.12,52.12 75.12,52.12 75.12,56.28 75.12,56.28 75.12,62.15 75.31,66.55 75.69,69.48 Z",
    "M 59.31,38.44 C 55.38,37.32 53.41,35.22 53.41,32.12 53.41,29.99 55.43,27.96 59.48,26.05 63.54,24.12 67.85,23.16 72.44,23.16 77.24,23.16 81.29,24.48 84.59,27.12 87.91,29.76 89.56,33.51 89.56,38.36 89.56,40.02 89.27,41.74 88.67,43.53 88.09,45.31 87.44,46.91 86.72,48.33 86.00,49.73 84.86,51.34 83.31,53.16 81.77,54.97 80.55,56.37 79.64,57.36 78.73,58.35 77.25,59.74 75.20,61.53 73.15,63.31 71.77,64.48 71.08,65.05 70.39,65.60 69.03,66.70 67.00,68.36 67.10,68.41 67.69,68.44 68.77,68.44 75.27,68.44 79.93,68.34 82.75,68.12 85.58,67.91 88.41,67.32 91.23,66.36 91.93,68.23 92.28,69.80 92.28,71.08 92.28,73.42 91.59,75.00 90.20,75.80 88.55,76.70 83.53,77.16 75.16,77.16 72.76,77.16 69.45,77.13 65.23,77.08 61.02,77.02 58.07,77.00 56.36,77.00 53.54,77.00 52.12,75.40 52.12,72.20 52.12,71.30 52.28,70.09 52.59,68.59 62.89,59.59 69.59,53.32 72.67,49.80 75.77,46.28 77.31,42.78 77.31,39.31 77.31,36.54 76.72,34.49 75.55,33.16 74.38,31.82 72.84,31.16 70.92,31.16 68.84,31.16 66.70,31.85 64.52,33.23 62.33,34.62 60.59,36.36 59.31,38.44 Z",
    "M 77.12,47.88 C 81.38,49.59 84.29,51.55 85.83,53.77 87.38,55.98 88.16,58.65 88.16,61.80 88.16,66.12 86.21,69.87 82.31,73.05 78.43,76.21 73.09,77.80 66.31,77.80 65.04,77.80 63.77,77.74 62.52,77.64 61.27,77.54 59.79,77.31 58.08,76.97 56.37,76.62 54.98,76.01 53.91,75.16 52.84,74.30 52.31,73.23 52.31,71.95 52.31,70.73 52.79,69.58 53.75,68.48 54.72,67.39 55.92,66.63 57.36,66.20 57.99,67.32 59.16,68.24 60.88,68.97 62.58,69.69 64.43,70.05 66.41,70.05 68.85,70.05 70.99,69.34 72.83,67.92 74.67,66.51 75.59,64.57 75.59,62.12 75.59,59.24 74.64,56.92 72.75,55.16 70.86,53.40 68.35,52.52 65.20,52.52 64.24,52.52 63.51,52.49 63.00,52.44 62.49,52.39 61.91,52.23 61.27,51.97 60.63,51.70 60.18,51.25 59.91,50.61 59.64,49.96 59.52,49.14 59.52,48.12 59.52,47.11 59.60,46.23 59.77,45.48 64.45,45.48 67.98,44.58 70.36,42.77 72.73,40.95 73.92,38.82 73.92,36.36 73.92,34.60 73.41,33.27 72.39,32.36 71.38,31.45 70.08,31.00 68.48,31.00 66.62,31.00 64.80,31.59 63.05,32.77 61.29,33.93 60.11,35.45 59.52,37.31 57.55,37.05 56.02,36.40 54.95,35.36 53.89,34.32 53.36,33.18 53.36,31.95 53.36,29.78 54.96,27.77 58.16,25.92 61.35,24.08 65.35,23.16 70.16,23.16 75.28,23.16 79.28,24.37 82.16,26.80 85.04,29.23 86.48,32.12 86.48,35.48 86.48,41.20 83.36,45.32 77.12,47.88 Z",
    "M 63.56,53.64 C 64.73,53.70 66.75,53.72 69.64,53.72 71.72,53.72 73.70,53.64 75.56,53.48 75.56,48.58 75.64,43.32 75.80,37.72 75.37,38.25 74.29,39.57 72.55,41.69 70.82,43.79 69.22,45.80 67.75,47.72 66.29,49.64 64.89,51.61 63.56,53.64 Z M 73.56,25.64 C 75.38,23.98 78.30,23.16 82.36,23.16 84.87,23.16 86.82,23.34 88.20,23.72 88.20,23.88 88.13,25.01 88.00,27.12 87.86,29.23 87.74,32.16 87.64,35.92 87.54,39.68 87.48,43.64 87.48,47.80 87.48,47.80 87.48,53.31 87.48,53.31 91.37,53.00 93.95,52.55 95.23,51.95 95.77,53.45 96.05,55.03 96.05,56.69 96.05,58.98 95.51,60.37 94.44,60.84 93.05,61.48 90.73,61.82 87.48,61.88 87.48,67.64 88.12,72.60 89.41,76.77 87.43,77.45 85.16,77.80 82.59,77.80 79.72,77.80 77.80,77.27 76.84,76.20 75.88,75.13 75.41,73.29 75.41,70.69 75.41,70.42 75.46,67.45 75.56,61.80 66.23,61.80 59.64,61.64 55.80,61.31 54.30,61.21 53.32,60.77 52.84,60.00 52.36,59.23 52.12,58.07 52.12,56.52 52.12,56.09 52.18,55.34 52.28,54.28 54.47,50.55 57.95,45.46 62.72,39.00 67.49,32.54 71.10,28.09 73.56,25.64 Z",
    "M 70.08,41.23 C 75.73,41.23 80.13,42.93 83.28,46.33 86.43,49.71 88.00,53.96 88.00,59.08 88.00,62.39 87.33,65.31 86.00,67.84 84.66,70.38 82.88,72.35 80.64,73.77 78.40,75.17 76.04,76.21 73.56,76.88 71.08,77.54 68.48,77.88 65.77,77.88 61.92,77.88 58.68,77.37 56.03,76.36 53.39,75.35 52.08,73.99 52.08,72.28 52.08,71.16 52.57,70.08 53.56,69.05 54.55,68.01 55.79,67.30 57.28,66.92 58.82,69.16 61.54,70.28 65.44,70.28 68.48,70.28 70.91,69.31 72.72,67.36 74.53,65.41 75.44,63.08 75.44,60.36 75.44,57.21 74.21,54.48 71.75,52.17 69.30,49.85 65.73,48.69 61.05,48.69 59.60,48.69 57.81,48.87 55.69,49.23 55.14,48.45 54.88,46.66 54.88,43.88 54.88,43.09 55.22,36.42 55.92,23.88 57.84,23.99 64.37,24.05 75.52,24.05 81.44,24.05 85.10,23.84 86.48,23.41 87.29,24.95 87.69,26.36 87.69,27.64 87.69,29.18 87.12,30.44 86.00,31.41 84.88,32.37 83.33,32.84 81.36,32.84 77.30,32.84 72.48,32.68 66.88,32.36 66.88,32.36 65.69,41.56 65.69,41.56 67.02,41.34 68.48,41.23 70.08,41.23 Z",
    "M 71.36,69.88 C 73.12,69.88 74.50,69.09 75.52,67.52 76.54,65.95 77.05,64.04 77.05,61.80 77.05,59.30 76.54,57.02 75.52,54.97 74.50,52.91 72.85,51.88 70.56,51.88 68.80,51.88 66.77,52.49 64.48,53.72 64.48,56.06 64.59,58.12 64.80,59.88 65.02,61.64 65.35,63.32 65.80,64.92 66.25,66.52 66.95,67.74 67.88,68.59 68.81,69.45 69.97,69.88 71.36,69.88 Z M 70.72,77.80 C 67.20,77.80 64.18,77.16 61.67,75.88 59.17,74.59 57.24,72.84 55.88,70.59 54.52,68.36 53.55,65.96 52.95,63.41 52.37,60.84 52.08,58.04 52.08,55.00 52.08,49.62 53.10,44.55 55.16,39.81 57.21,35.06 60.38,31.10 64.67,27.92 68.97,24.75 73.95,23.16 79.59,23.16 81.95,23.16 83.64,23.49 84.67,24.16 85.71,24.82 86.23,26.09 86.23,27.95 86.23,28.98 86.08,30.26 85.77,31.80 84.54,31.42 82.83,31.23 80.64,31.23 75.63,31.23 71.80,32.80 69.16,35.92 66.52,39.04 64.99,42.95 64.56,47.64 67.60,45.30 70.83,44.12 74.23,44.12 79.04,44.18 82.78,45.75 85.47,48.84 88.16,51.94 89.52,55.64 89.52,59.95 89.52,64.70 87.86,68.87 84.55,72.44 81.24,76.01 76.63,77.80 70.72,77.80 Z",
    "M 80.52,32.44 C 72.79,32.71 65.32,32.84 58.12,32.84 56.20,32.84 54.74,32.32 53.75,31.28 52.77,30.24 52.28,28.92 52.28,27.31 52.28,25.98 52.60,24.68 53.23,23.41 56.22,23.84 59.64,24.05 63.48,24.05 79.48,24.05 89.05,23.99 92.20,23.88 92.68,24.73 92.92,25.75 92.92,26.92 92.92,27.93 92.76,28.92 92.44,29.88 90.89,34.52 83.77,48.66 71.08,72.28 69.96,74.42 68.92,75.87 67.95,76.64 66.99,77.41 65.96,77.80 64.84,77.80 60.89,77.80 57.32,76.25 54.12,73.16 58.66,68.90 63.62,62.43 69.03,53.77 74.45,45.10 78.27,37.99 80.52,32.44 Z",
    "M 71.05,31.08 C 68.00,31.08 66.48,32.31 66.48,34.77 66.48,36.36 67.09,38.06 68.31,39.88 69.54,41.69 70.88,42.92 72.31,43.56 74.61,41.96 75.77,39.37 75.77,35.80 75.77,32.81 74.19,31.23 71.05,31.08 Z M 71.05,69.88 C 73.07,69.88 74.65,69.20 75.80,67.84 76.94,66.48 77.52,64.79 77.52,62.77 77.52,60.63 76.86,58.55 75.55,56.53 74.24,54.50 72.34,52.87 69.84,51.64 68.34,52.55 67.07,54.09 66.03,56.28 65.00,58.47 64.48,60.66 64.48,62.84 64.48,64.92 65.02,66.61 66.08,67.92 67.15,69.23 68.80,69.88 71.05,69.88 Z M 85.19,73.56 C 81.88,76.39 77.19,77.80 71.11,77.80 65.04,77.80 60.34,76.39 57.03,73.56 53.73,70.73 52.08,67.34 52.08,63.41 52.08,59.41 53.09,56.33 55.11,54.17 57.14,52.01 60.10,50.04 64.00,48.28 60.80,46.73 58.36,44.88 56.67,42.72 54.99,40.56 54.16,37.83 54.16,34.52 54.16,31.16 55.80,28.43 59.11,26.33 62.42,24.21 66.40,23.16 71.05,23.16 76.38,23.16 80.52,24.31 83.48,26.61 86.44,28.90 87.92,31.65 87.92,34.84 87.92,37.24 87.07,39.53 85.36,41.72 83.65,43.91 81.22,45.67 78.08,47.00 82.02,48.76 85.02,50.91 87.08,53.44 89.13,55.97 90.16,59.29 90.16,63.41 90.16,67.34 88.50,70.73 85.19,73.56 Z",
    "M 70.23,31.08 C 68.47,31.08 67.09,31.87 66.08,33.44 65.07,35.01 64.56,36.92 64.56,39.16 64.56,41.67 65.07,43.95 66.08,46.00 67.09,48.05 68.74,49.08 71.05,49.08 72.80,49.08 74.83,48.46 77.12,47.23 77.12,44.89 77.02,42.84 76.80,41.08 76.59,39.32 76.25,37.64 75.80,36.05 75.35,34.45 74.66,33.21 73.72,32.36 72.78,31.51 71.62,31.08 70.23,31.08 Z M 70.88,23.16 C 74.39,23.16 77.41,23.80 79.92,25.08 82.43,26.36 84.36,28.12 85.72,30.36 87.08,32.60 88.05,35.00 88.64,37.56 89.22,40.12 89.52,42.92 89.52,45.95 89.52,51.35 88.49,56.42 86.44,61.17 84.38,65.91 81.21,69.87 76.92,73.05 72.63,76.21 67.66,77.80 62.00,77.80 59.66,77.80 57.96,77.46 56.92,76.80 55.88,76.13 55.36,74.87 55.36,73.00 55.36,71.94 55.52,70.66 55.84,69.16 57.07,69.53 58.77,69.72 60.95,69.72 65.97,69.72 69.80,68.16 72.44,65.05 75.08,61.92 76.62,58.01 77.05,53.31 74.00,55.67 70.77,56.84 67.36,56.84 62.55,56.79 58.80,55.22 56.11,52.12 53.42,49.03 52.08,45.32 52.08,41.00 52.08,36.25 53.73,32.09 57.03,28.52 60.34,24.95 64.96,23.16 70.88,23.16 Z",
  
  ]
  const middlePaths = [
    "M 65.20,70.36 C 61.39,75.32 56.28,77.80 49.88,77.80 43.48,77.80 38.40,75.34 34.64,70.41 30.88,65.47 29.00,58.84 29.00,50.52 29.00,42.14 30.88,35.49 34.64,30.56 38.40,25.62 43.48,23.16 49.88,23.16 56.28,23.16 61.39,25.62 65.20,30.56 69.02,35.49 70.92,42.14 70.92,50.52 70.92,58.79 69.02,65.40 65.20,70.36 Z M 58.36,51.88 C 58.36,46.54 57.61,41.75 56.11,37.48 54.62,33.21 52.54,31.08 49.88,31.08 47.38,31.08 45.32,32.72 43.72,36.00 42.11,39.28 41.31,43.30 41.31,48.05 41.31,53.81 42.05,58.90 43.55,63.33 45.05,67.75 47.13,69.95 49.80,69.95 52.36,69.95 54.43,68.23 56.00,64.77 57.57,61.30 58.36,57.00 58.36,51.88 Z",
    "M 57.69,69.48 C 61.09,69.48 63.73,69.38 65.59,69.16 66.07,70.23 66.31,71.41 66.31,72.69 66.31,75.67 65.01,77.16 62.41,77.16 61.93,77.16 59.93,77.13 56.41,77.08 52.88,77.02 49.60,77.00 46.56,77.00 41.55,77.00 37.98,77.02 35.84,77.08 35.31,75.59 35.05,74.28 35.05,73.16 35.05,70.54 36.86,69.23 40.48,69.23 40.48,69.23 45.12,69.23 45.12,69.23 45.12,68.38 45.15,65.62 45.20,60.95 45.25,56.29 45.28,52.20 45.28,48.69 45.28,42.55 45.20,36.92 45.05,31.80 39.29,32.39 35.95,32.77 35.05,32.92 34.83,31.54 34.72,30.50 34.72,29.80 34.72,26.50 36.08,24.68 38.80,24.36 45.09,23.56 49.78,23.16 52.88,23.16 54.16,23.16 55.29,23.48 56.28,24.12 57.27,24.76 57.77,25.77 57.77,27.16 57.77,27.59 57.71,29.05 57.59,31.56 57.49,34.06 57.38,37.23 57.28,41.05 57.18,44.86 57.12,48.55 57.12,52.12 57.12,52.12 57.12,56.28 57.12,56.28 57.12,62.15 57.31,66.55 57.69,69.48 Z",
    "M 37.31,38.44 C 33.38,37.32 31.41,35.22 31.41,32.12 31.41,29.99 33.43,27.96 37.48,26.05 41.54,24.12 45.85,23.16 50.44,23.16 55.24,23.16 59.29,24.48 62.59,27.12 65.91,29.76 67.56,33.51 67.56,38.36 67.56,40.02 67.27,41.74 66.67,43.53 66.09,45.31 65.44,46.91 64.72,48.33 64.00,49.73 62.86,51.34 61.31,53.16 59.77,54.97 58.55,56.37 57.64,57.36 56.73,58.35 55.25,59.74 53.20,61.53 51.15,63.31 49.77,64.48 49.08,65.05 48.39,65.60 47.03,66.70 45.00,68.36 45.10,68.41 45.69,68.44 46.77,68.44 53.27,68.44 57.93,68.34 60.75,68.12 63.58,67.91 66.41,67.32 69.23,66.36 69.93,68.23 70.28,69.80 70.28,71.08 70.28,73.42 69.59,75.00 68.20,75.80 66.55,76.70 61.53,77.16 53.16,77.16 50.76,77.16 47.45,77.13 43.23,77.08 39.02,77.02 36.07,77.00 34.36,77.00 31.54,77.00 30.12,75.40 30.12,72.20 30.12,71.30 30.28,70.09 30.59,68.59 40.89,59.59 47.59,53.32 50.67,49.80 53.77,46.28 55.31,42.78 55.31,39.31 55.31,36.54 54.72,34.49 53.55,33.16 52.38,31.82 50.84,31.16 48.92,31.16 46.84,31.16 44.70,31.85 42.52,33.23 40.33,34.62 38.59,36.36 37.31,38.44 Z",
    "M 57.12,47.88 C 61.38,49.59 64.29,51.55 65.83,53.77 67.38,55.98 68.16,58.65 68.16,61.80 68.16,66.12 66.21,69.87 62.31,73.05 58.43,76.21 53.09,77.80 46.31,77.80 45.04,77.80 43.77,77.74 42.52,77.64 41.27,77.54 39.79,77.31 38.08,76.97 36.37,76.62 34.98,76.01 33.91,75.16 32.84,74.30 32.31,73.23 32.31,71.95 32.31,70.73 32.79,69.58 33.75,68.48 34.72,67.39 35.92,66.63 37.36,66.20 37.99,67.32 39.16,68.24 40.88,68.97 42.58,69.69 44.43,70.05 46.41,70.05 48.85,70.05 50.99,69.34 52.83,67.92 54.67,66.51 55.59,64.57 55.59,62.12 55.59,59.24 54.64,56.92 52.75,55.16 50.86,53.40 48.35,52.52 45.20,52.52 44.24,52.52 43.51,52.49 43.00,52.44 42.49,52.39 41.91,52.23 41.27,51.97 40.63,51.70 40.18,51.25 39.91,50.61 39.64,49.96 39.52,49.14 39.52,48.12 39.52,47.11 39.60,46.23 39.77,45.48 44.45,45.48 47.98,44.58 50.36,42.77 52.73,40.95 53.92,38.82 53.92,36.36 53.92,34.60 53.41,33.27 52.39,32.36 51.38,31.45 50.08,31.00 48.48,31.00 46.62,31.00 44.80,31.59 43.05,32.77 41.29,33.93 40.11,35.45 39.52,37.31 37.55,37.05 36.02,36.40 34.95,35.36 33.89,34.32 33.36,33.18 33.36,31.95 33.36,29.78 34.96,27.77 38.16,25.92 41.35,24.08 45.35,23.16 50.16,23.16 55.28,23.16 59.28,24.37 62.16,26.80 65.04,29.23 66.48,32.12 66.48,35.48 66.48,41.20 63.36,45.32 57.12,47.88 Z",
    "M 39.56,53.64 C 40.73,53.70 42.75,53.72 45.64,53.72 47.72,53.72 49.70,53.64 51.56,53.48 51.56,48.58 51.64,43.32 51.80,37.72 51.37,38.25 50.29,39.57 48.55,41.69 46.82,43.79 45.22,45.80 43.75,47.72 42.29,49.64 40.89,51.61 39.56,53.64 Z M 49.56,25.64 C 51.38,23.98 54.30,23.16 58.36,23.16 60.87,23.16 62.82,23.34 64.20,23.72 64.20,23.88 64.13,25.01 64.00,27.12 63.86,29.23 63.74,32.16 63.64,35.92 63.54,39.68 63.48,43.64 63.48,47.80 63.48,47.80 63.48,53.31 63.48,53.31 67.37,53.00 69.95,52.55 71.23,51.95 71.77,53.45 72.05,55.03 72.05,56.69 72.05,58.98 71.51,60.37 70.44,60.84 69.05,61.48 66.73,61.82 63.48,61.88 63.48,67.64 64.12,72.60 65.41,76.77 63.43,77.45 61.16,77.80 58.59,77.80 55.72,77.80 53.80,77.27 52.84,76.20 51.88,75.13 51.41,73.29 51.41,70.69 51.41,70.42 51.46,67.45 51.56,61.80 42.23,61.80 35.64,61.64 31.80,61.31 30.30,61.21 29.32,60.77 28.84,60.00 28.36,59.23 28.12,58.07 28.12,56.52 28.12,56.09 28.18,55.34 28.28,54.28 30.47,50.55 33.95,45.46 38.72,39.00 43.49,32.54 47.10,28.09 49.56,25.64 Z",
    "M 50.08,41.23 C 55.73,41.23 60.13,42.93 63.28,46.33 66.43,49.71 68.00,53.96 68.00,59.08 68.00,62.39 67.33,65.31 66.00,67.84 64.66,70.38 62.88,72.35 60.64,73.77 58.40,75.17 56.04,76.21 53.56,76.88 51.08,77.54 48.48,77.88 45.77,77.88 41.92,77.88 38.68,77.37 36.03,76.36 33.39,75.35 32.08,73.99 32.08,72.28 32.08,71.16 32.57,70.08 33.56,69.05 34.55,68.01 35.79,67.30 37.28,66.92 38.82,69.16 41.54,70.28 45.44,70.28 48.48,70.28 50.91,69.31 52.72,67.36 54.53,65.41 55.44,63.08 55.44,60.36 55.44,57.21 54.21,54.48 51.75,52.17 49.30,49.85 45.73,48.69 41.05,48.69 39.60,48.69 37.81,48.87 35.69,49.23 35.14,48.45 34.88,46.66 34.88,43.88 34.88,43.09 35.22,36.42 35.92,23.88 37.84,23.99 44.37,24.05 55.52,24.05 61.44,24.05 65.10,23.84 66.48,23.41 67.29,24.95 67.69,26.36 67.69,27.64 67.69,29.18 67.12,30.44 66.00,31.41 64.88,32.37 63.33,32.84 61.36,32.84 57.30,32.84 52.48,32.68 46.88,32.36 46.88,32.36 45.69,41.56 45.69,41.56 47.02,41.34 48.48,41.23 50.08,41.23 Z",
    "M 50.36,69.88 C 52.12,69.88 53.50,69.09 54.52,67.52 55.54,65.95 56.05,64.04 56.05,61.80 56.05,59.30 55.54,57.02 54.52,54.97 53.50,52.91 51.85,51.88 49.56,51.88 47.80,51.88 45.77,52.49 43.48,53.72 43.48,56.06 43.59,58.12 43.80,59.88 44.02,61.64 44.35,63.32 44.80,64.92 45.25,66.52 45.95,67.74 46.88,68.59 47.81,69.45 48.97,69.88 50.36,69.88 Z M 49.72,77.80 C 46.20,77.80 43.18,77.16 40.67,75.88 38.17,74.59 36.24,72.84 34.88,70.59 33.52,68.36 32.55,65.96 31.95,63.41 31.37,60.84 31.08,58.04 31.08,55.00 31.08,49.62 32.10,44.55 34.16,39.81 36.21,35.06 39.38,31.10 43.67,27.92 47.97,24.75 52.95,23.16 58.59,23.16 60.95,23.16 62.64,23.49 63.67,24.16 64.71,24.82 65.23,26.09 65.23,27.95 65.23,28.98 65.08,30.26 64.77,31.80 63.54,31.42 61.83,31.23 59.64,31.23 54.63,31.23 50.80,32.80 48.16,35.92 45.52,39.04 43.99,42.95 43.56,47.64 46.60,45.30 49.83,44.12 53.23,44.12 58.04,44.18 61.78,45.75 64.47,48.84 67.16,51.94 68.52,55.64 68.52,59.95 68.52,64.70 66.86,68.87 63.55,72.44 60.24,76.01 55.63,77.80 49.72,77.80 Z",
    "M 58.52,32.44 C 50.79,32.71 43.32,32.84 36.12,32.84 34.20,32.84 32.74,32.32 31.75,31.28 30.77,30.24 30.28,28.92 30.28,27.31 30.28,25.98 30.60,24.68 31.23,23.41 34.22,23.84 37.64,24.05 41.48,24.05 57.48,24.05 67.05,23.99 70.20,23.88 70.68,24.73 70.92,25.75 70.92,26.92 70.92,27.93 70.76,28.92 70.44,29.88 68.89,34.52 61.77,48.66 49.08,72.28 47.96,74.42 46.92,75.87 45.95,76.64 44.99,77.41 43.96,77.80 42.84,77.80 38.89,77.80 35.32,76.25 32.12,73.16 36.66,68.90 41.62,62.43 47.03,53.77 52.45,45.10 56.27,37.99 58.52,32.44 Z",
    "M 50.05,31.08 C 47.00,31.08 45.48,32.31 45.48,34.77 45.48,36.36 46.09,38.06 47.31,39.88 48.54,41.69 49.88,42.92 51.31,43.56 53.61,41.96 54.77,39.37 54.77,35.80 54.77,32.81 53.19,31.23 50.05,31.08 Z M 50.05,69.88 C 52.07,69.88 53.65,69.20 54.80,67.84 55.94,66.48 56.52,64.79 56.52,62.77 56.52,60.63 55.86,58.55 54.55,56.53 53.24,54.50 51.34,52.87 48.84,51.64 47.34,52.55 46.07,54.09 45.03,56.28 44.00,58.47 43.48,60.66 43.48,62.84 43.48,64.92 44.02,66.61 45.08,67.92 46.15,69.23 47.80,69.88 50.05,69.88 Z M 64.19,73.56 C 60.88,76.39 56.19,77.80 50.11,77.80 44.04,77.80 39.34,76.39 36.03,73.56 32.73,70.73 31.08,67.34 31.08,63.41 31.08,59.41 32.09,56.33 34.11,54.17 36.14,52.01 39.10,50.04 43.00,48.28 39.80,46.73 37.36,44.88 35.67,42.72 33.99,40.56 33.16,37.83 33.16,34.52 33.16,31.16 34.80,28.43 38.11,26.33 41.42,24.21 45.40,23.16 50.05,23.16 55.38,23.16 59.52,24.31 62.48,26.61 65.44,28.90 66.92,31.65 66.92,34.84 66.92,37.24 66.07,39.53 64.36,41.72 62.65,43.91 60.22,45.67 57.08,47.00 61.02,48.76 64.02,50.91 66.08,53.44 68.13,55.97 69.16,59.29 69.16,63.41 69.16,67.34 67.50,70.73 64.19,73.56 Z",
    "M 49.23,31.08 C 47.47,31.08 46.09,31.87 45.08,33.44 44.07,35.01 43.56,36.92 43.56,39.16 43.56,41.67 44.07,43.95 45.08,46.00 46.09,48.05 47.74,49.08 50.05,49.08 51.80,49.08 53.83,48.46 56.12,47.23 56.12,44.89 56.02,42.84 55.80,41.08 55.59,39.32 55.25,37.64 54.80,36.05 54.35,34.45 53.66,33.21 52.72,32.36 51.78,31.51 50.62,31.08 49.23,31.08 Z M 49.88,23.16 C 53.39,23.16 56.41,23.80 58.92,25.08 61.43,26.36 63.36,28.12 64.72,30.36 66.08,32.60 67.05,35.00 67.64,37.56 68.22,40.12 68.52,42.92 68.52,45.95 68.52,51.35 67.49,56.42 65.44,61.17 63.38,65.91 60.21,69.87 55.92,73.05 51.63,76.21 46.66,77.80 41.00,77.80 38.66,77.80 36.96,77.46 35.92,76.80 34.88,76.13 34.36,74.87 34.36,73.00 34.36,71.94 34.52,70.66 34.84,69.16 36.07,69.53 37.77,69.72 39.95,69.72 44.97,69.72 48.80,68.16 51.44,65.05 54.08,61.92 55.62,58.01 56.05,53.31 53.00,55.67 49.77,56.84 46.36,56.84 41.55,56.79 37.80,55.22 35.11,52.12 32.42,49.03 31.08,45.32 31.08,41.00 31.08,36.25 32.73,32.09 36.03,28.52 39.34,24.95 43.96,23.16 49.88,23.16 Z"
  ]
  const bg = "M 43.00,0.44 C 85.90,-5.06 115.91,42.00 91.64,78.00 87.97,83.44 83.44,87.97 78.00,91.64 71.46,96.05 64.75,98.35 57.00,99.56 51.45,100.43 45.48,100.11 40.00,99.00 -0.84,90.67 -13.92,39.49 17.00,12.18 25.02,5.10 32.71,2.34 43.00,0.44 Z"
  return(
    <div className={className}>
      <div className={firstChildClassName}></div>
    <svg className={imageClassName} viewBox="0 0 100 100">
      {
         num < 9
          ?
          <path className={pathClassName} d={middlePaths[num+1]}/>
          :
          <g className={pathClassName}>
            <path d={leftPaths[0]}/>
            <path d={rightPaths[(num+1)%10]}/>
          </g>
      }
    </svg>
    </div>
  )
}

export default Number