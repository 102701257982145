import { useRef } from 'react'
import { Waypoint } from 'react-waypoint';
const AnimateOnVisible = ({ className, type = 'fade', duration = 0, delay = 0, children }) => {
  const durationClasses = {
    0: 'animate-dur-0',
    100: 'animate-dur-100',
    200: 'aniamte-dur-200',
    300: 'animate-dur-300',
    400: 'animate-dur-400',
    500: 'animate-dur-500',
    600: 'animate-dur-600',
    700: 'animate-dur-700',
    800: 'animate-dur-800',
    900: 'animate-dur-900',
    1000: 'animate-dur-1000',
  };
  const delayClasses = {
    0: 'animate-del-0',
    100: 'animate-del-100',
    200: 'aniamte-del-200',
    300: 'animate-del-300',
    400: 'animate-del-400',
    500: 'animate-del-500',
    600: 'animate-del-600',
    700: 'animate-del-700',
    800: 'animate-del-800',
    900: 'animate-del-900',
    1000: 'animate-del-1000',
  };
  const defaultClasses = {
    'fade':'opacity-0',
    'dim':'opacity-100'
  }
  const r = useRef()
  const addFadeInClass = (ref, dim=false) => {
    ref.current.classList.add(dim ? 'animate-dim' : 'animate-fade-in')
  }
  const removeFadeInClass = (ref, dim=false) => {
    ref.current.classList.remove(dim ? 'animate-dim' : 'animate-fade-in')
  }
  return (
    <Waypoint
      onEnter={() => { console.log("TYPE", type, type==='dim')
        addFadeInClass(r, type==='dim');
      }}
      onLeave={() => {
        removeFadeInClass(r, type==='dim');
      }}
    >
      <div
        className={`w-full flex flex-col items-center z-30 ${defaultClasses[type]} ${durationClasses[duration]} ${delayClasses[delay]}${className ? ' ' + className : ''}`}
        ref={r}
      >
        {children}
      </div>
    </Waypoint>

    
  );
};

export default AnimateOnVisible
