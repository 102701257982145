const dogClassNames = {color1:'fill-black',color2:'fill-sky opacity-30',color3:'fill-waterDark', collar:'fill-sun', black:'fill-black', white:'fill-white', eyes:'fill-black',reflect1:'fill-white', reflect2:'fill-white opacity-20', dark:'fill-silver', light:'fill-black', med:'fill-black', white:'fill-white', eyes:'fill-darkBlue'}
const windowClassNames = {glass:'fill-white opacity-10', bg:'fill-waterDark dark:fill-island', dark:'fill-lightblue', outer:'fill-sky dark:fill-island',trim:'fill-black', lightTrim:'fill-waterLight dark:fill-island', inside:'fill-island'}

const LonelyDog = ({className=""}) => (
  <svg
    className={className}
     viewBox="0 0 750 1022">
     <path className={windowClassNames.bg} d="m12 43 70-2 15-1 39-1 14-1h13l16-1h15l12-1h15l11-1h14l15-1 64-2 15-1h13l12-1h15l12-1h15l12-1h16l15-1h16l12-1h16l12-1 39-1 15-1 38-1 17-1 37-1 17-1h15l12-1 41-1v40l-1 17v32l-1 15v34l-1 17v32l-1 17-1 80-1 17-1 81-1 17-1 81-1 17v34l-1 14v33l-1 17-1 81-1 17-1 81-1 17-2 130-1 14c0 2.2-.2 5.9 1 7.7 2 3.1 14.2 5 18 5.3v22c-4.1 0-19-.7-21.7 1-3.5 2.2-5.7 13-6.3 17h-81l-15-1h-87l-15-1h-91l-15-1h-91l-15-1h-91l-15-1h-91l-14-1H59c-.9-3.8-2-9.7-6.1-11.4-4.2-1.7-15.4 1-17.9-2.2-1.5-1.9-1-10.7-1-13.4 0-1.7 0-4 1-5.4 3-4 21.6-3.2 25-8.8 1.5-2.6 0-9.4 0-12.8l-1-24-1-10v-11l-1-15-1-15-2-41-1-10v-9l-1-17-1-15-1-20-2-41-1-10v-8l-1-19-1-10v-11l-1-15-1-15-2-41-1-10v-9l-1-18-1-10v-9l-1-18-1-17-1-17-2-41-1-10v-11l-1-15-1-15-2-41-1-10v-12l-1-13-1-16-1-19-2-41-1-10v-9l-1-18-1-19-1-17-1-16-2-41-1-15-1-17-1-31Z"/>
      <path className={windowClassNames.outer} d="M684 87h-25l-15 1h-21l-15 1h-20l-17 1h-20l-16 1-54 1-17 1h-21l-13 1h-20l-17 1h-20l-16 1-54 1-17 1h-21l-13 1h-21l-17 1-54 1-17 1-55 1-12 1H64l2 33 1 16 1 17 1 22 1 14 1 22 1 10v8l1 18 1 10v8l1 18 1 14 2 36 2 37 1 17v53h3l1 16 1 23 1 10v8l1 18 1 17 1 15 2 39 1 14 2 40 1 14 2 39 1 10v8l1 18 1 17 1 15 2 39 1 10v11l1 13 1 16 1 17 1 27h556v-26l1-17 1-68 1-17v-26l1-17v-25l1-17 2-110 1-17v-26l1-17 2-110 1-17v-26l1-17v-25l1-17v-26l1-17 1-68 1-17 1-69 1-15V87Z"/>
      <path className={windowClassNames.inside} d="m385 143.3 248-3.3-15 723.7-234 9.3 1-729.8ZM90.7 136.3l246-8.6L360 886l-222-3.7-47.3-746Z"/>
      <path className={dogClassNames.dark} d="M358 772c-9.7-113.5 121.4-76.5 116 15l12 33.2 4 15.8 2.7 16.9c1.6 3.7 7.7 5.1 10.9 7.7 5 4.2 8 11.2 9.4 17.4H332l3.6-15 2-9 6.3-20 3-13 6-22 2.9-17 2.2-10Z"/>
      <path className={windowClassNames.glass} d="m385 143.3 248-3.3-15 723.7-234 9.3 1-729.8ZM90.7 136.3l246-8.6L360 886l-222-3.7-47.3-746Z"/>
      <path className={windowClassNames.trim} d="M715 40c-.4 4.4-8 11.5-11 15-3.2 3.6-12.4 13.4-14 17a38 38 0 0 0-2 14v90l-1 17v91l-1 15v90l-1 17v91l-1 15v90l-1 17v91l-1 15v88l-1 15v92c0 8.8 2 8 7 15-6.7-3.6-11.2-10.3-16.1-16-2.3-2.6-5.3-5.7-6-9-1.8-4.3 0-14.6 0-20v-26l1.1-17 1-68 1-17 1-68 1-17v-27l1-15 2-110 1-17v-26l1-17v-25l1-17v-26l1-17v-25l1-17 1-69 1-17 1-68 1-17v-26l1-17 1-38-14 1h-19l-17 1h-20l-16 1-54 1-17 1h-21l-15 1-90 2-15 1h-23l-17 1h-17l-16 1h-21l-13 1h-21l-17 1h-19l-16 1h-20l-16 1h-20l-16 1h-20l-15 1H79l-15 1 3 62 1 10v10l1 15 1 10v8l1 18 1 17 1 15 2 39 1 14 1 22 1 15 1 16 1 22 1 14 1 20 1 17 1 21 1 17 1 15 2 39 1 14 2 40 1 10v8l1 17 1 10v8l1 18 1 17 1 15 2 39 1 17 1 16 2 37 1 20 1 17 1 15 2.2 38v14L96 925l-28 27c3.8-7.2 18.5-23.7 24.6-31 2.5-3 8.4-9.6 9.5-13 1.1-2.6 0-16.7 0-21l-1.1-17-1-16-2-41-1-17-1-17-1-25-1-10v-9l-1-19-1-10v-9l-1-18-1-16-1-17-1-25-1-10v-9l-1-19-1-17-1-17-1-17-1-25-1-19-1-17-1-16-2-42-1-16-1-17-1-25-1-10v-9l-1-19-1-10v-9l-1-18-1-16-1-17-1-25-1-10v-9l-1-17-1-16-1-18-1-24-1-16c0-4.5.1-12-2-16-3.8-7.3-27-27.8-32-37l26 25 11 10 18 1 15-1 62-2 12-1h15l17-1h14l12-1h14l12-1h15l12-1 62-2 12-1h14l17-1h14l12-1 38-1 15-1 36-1 12-1h15l16-1h14l12-1 38-1 15-1 38-1 22-2 13.2-13L715 40Z"/>
      <path className={windowClassNames.lightTrim} d="M105 917c1.2-2 1.9-3.7 4.1-5 2-1.1 4.7-1 6.9-1h541c2 0 5.1-.1 7 .6a55 55 0 0 1 15 17.4h-22l-17-1-126-2-15-1h-32l-16-1h-32l-17-1h-30l-17-1h-31l-17-1h-30l-17-1h-31l-17-1h-32l-15-1h-32l-12-1h-16Zm-70 48 23-4h366l15 1h272c8.7 0 18 .3 26 4H55l-20-1ZM349 783c46.5-95.5 89.5-83 126 7a290.4 290.4 0 0 1 17 56c1.4 6.8 2 5.1 2 13a26.6 26.6 0 0 1 20 25H326c.2-9.1 3.3-16.4 5.6-25l13.4-52c1.5-7.5 5.1-16.6 4-24Z"/>
      <path className={dogClassNames.color2} d="m352.6 769.6 6.3-14.5L463 775.2l6 13.2s-29.4 63.7-116.4-18.8ZM384 886c8.3-56.7 79.2-35.6 74 0h-74Zm-7-2"/>
      <g className="animate-puppy origin-bottom">
            <path className={dogClassNames.color1} d="M468.4 770.7c-31 38-85.1 8-108.6-14.9 0 0-7.3 12.8-10.8 27.2-1 3.2-6.4 13.3-9.4 14.4-3.3 1.2-9-3.1-11.6-5a87.5 87.5 0 0 1-23.9-23.4 127.6 127.6 0 0 1-20.1-53c-1.5-8.3-3-12-3-21v-28c0-2-.1-5 .6-6.8 1.8-5 10.8-10.2 15.2-13 11.1-7.2 22.8-13.7 32-23.4 7-7.6 13.8-15.5 20-23.8 2.2-2.8 10.3-13.8 12.5-15.4 6-4.4 16-.4 19.7 5.4 9.8-3.2 21.7-3 32-3l18 1.4c6.2-.2 9-5 17-5.4 2.2 0 5 0 7 .7 9.6 3.4 23.3 20 29 28.3 5 7.5 11 20.4 14 29 1.3 4.1 2.5 11.8 4.3 14.9 3.4 5.8 12.4 6.3 19.6 12.6 6.8 6 6.1 14.3 6.1 22.5 0 5 .1 9.8-3.4 13.7-4.2 4.5-10.9 5.1-14 11.3l-2.7 8c-1.6 3.4-4 5.8-5.3 10l-1 7a131 131 0 0 1-8 30c-3.5 8.5-9 17.4-18.6 19-5.2-17.5-6.6-19.3-6.6-19.3Z"/>
            <path className={dogClassNames.color2} d="M469 749.5c-29.5 4-54.5 3.3-78.8-12.4-.3-5.5-.4-10-3.5-32.9-4-37.9-10.8-96-13.8-104.6l8-7c35.7-1.7 49.5-.4 73.1 6.7 16.7 5.4 35.8 29.3 44.4 65.6l-29.5 84.6ZM304.4 645c-13.8 6.8-17 11.5-20 16-1.5 10.3 11 106.8 34 104.5 68.8-11.6 46.8-171.3 45-178-15.4 18.6-29 41.5-59 57.5Zm60.1-56.8c1-5 12.2-1 16 3.9m43.2-47.5"/>
            <path className={dogClassNames.color3} d="m380.4 592.4-6.8 6.5c5.3 17 4.5 65 3 90.6-.6 9.8-2.2 16.5-2.2 16.5s-1.4-29.6-3.8-59.6c-2.6-30.3-6.2-61-6.2-61 6-2 13.4 2.8 16 7Zm22.6 10c5.4-.7 14.5-.7 18.7 3.3 7.1 6.7-7.4 10-11.7 10.3-4 .1-9.4-.4-13-2.4-5.3-3.1-4-7.3 1-9.6 1.6-.8 3.3-1.1 5-1.5Zm53.2-.8c-9.4 1-7.1 15.6 14.4 13.1 2.1-.7-9.9-13.3-14.4-13Zm13 68.8 52.6 6.7c3.8 5.6 6.2 17.6 2.2 23.3L508.4 712l-1.7 9-8.6 12.7c-28.3 45.6-64.7-10.7-82.3-46.4 11.1-8.4 29-14.4 53.5-17Z"/>
            <path className={dogClassNames.black} d="M469.8 671.8c3.3 11.5 36.4 32.8 51.1 6-13.8-18.3-45.8-11.3-51-6Z"/>
            <path className={dogClassNames.white} d="M495.1 663.4s0-6.7-2.2-14.3c-3.6-11.7-10.7-26-17.1-24.7-11 4.2 1.7 40 8 41.1 5.7.9 11.6 0 11.3-2.1ZM411 624.3c2.3-.1 3.6-.3 6 0 33.5 1.9 30.8 56.7-3 54.6-28.9-1.8-33.8-47-3-54.6Z"/>
            <path className={dogClassNames.black} d="M479.2 632c-8.2 4.1 2 35.2 11 30.5 9-4.4-4.6-32.9-11-30.6Zm-61.2-.4c23.4-4.7 26.5 34 8 39.8-9.1 2.8-16.4-4.2-19-12.4-3.2-10.5.4-22.9 11-27.4Z"/>
            <path className={dogClassNames.reflect2} d="M470 671.6c5 5.8 53.1 7.7 51 5.1-14.6-17-53.7-8.3-51-5ZM396.4 638.5c-.1 7.7 35.9 9 36 4.4 1.2-9.6-22.4-23.3-36-4.4Zm76.3-1.4c-.8 2.7 15.9 7 17 3.6-10-16.7-17.3-11.3-17-3.6ZM477.3 646.2c5-.6 4.3 3.6 2.4 4.6-2 1-5.8-.8-2.4-4.6Zm-73.2 3.7c10.4-2.7 12.3 8.4 5.8 11.2-6.9 3-14.4-5.7-5.8-11.2ZM508.2 711c-14.2-3.9-47 8.5-48.4 14 .5-1.2 44.1-9.4 46.2-9.2-.4.2.8-3.5 2.2-4.9Z"/>
      </g>
<path className={`${windowClassNames.outer} stroke-6 stroke-island`} d="m65.3 104 44 806.3 259.3.3L344.7 96 65.4 104Zm303.5 806.8-23.6-815L684 85l-21 825-294.3.8ZM91 136l245.5-8.5 21.5 758-220-1L91 136Zm295.5 6 1.5 733 228.5-10.5 20-725.5-250 3Z"/></svg>

)

export default LonelyDog

