const RestaurantIcon = ({className, pathClassName}) => (
  <svg
      className={className}
      viewBox="0 0 408 408">
        <g className={pathClassName}>
    <path d="M78.6 190.6a3 3 0 0 1 3-3l85.7-.6c3 0 6 .5 8.8 1.9 10.2 5 20 13 29 25 0 0 83 6.7 157.3-2.5a7.4 7.4 0 0 0 6.5-7.3v-.1A164.8 164.8 0 0 0 70 107.7c-13.9 19.2-2.1 47 21.5 49.3l1.9.2c54 2.5 99.7-1.5 99.7-1.5 47.4-64 118.4-14.1 138.9 2.4 3 2.5 3 7.2 0 9.7-20.5 16.6-91.5 66.4-139 2.5 0 0-64.7-5.7-131-.8h-.3a25.5 25.5 0 0 0-22.6 23.9c-.2 3-.2 6-.2 9 0 91 74 165 165 165 66.4 0 123.8-39.5 150-96.3a31.6 31.6 0 0 0-26.7-44.8c-63-4-122.2-.6-122.2-.6a77.7 77.7 0 0 1-32.5 26.6c-1.7-.7-3.5-1-5.3-.7l-85.8-.8a3 3 0 0 1-3-3v-1a3 3 0 0 1 3-3l85.8-.7c4.5 0 8.1-1.6 8.1-3.4 0-1.9-3.5-3.4-8-3.5l-86-.8a3 3 0 0 1-2.9-3v-1a3 3 0 0 1 3-3l85.8-.7c4.5 0 8.1-1.6 8.1-3.4 0-1.9-3.5-3.4-8-3.5l-86-.8a3 3 0 0 1-2.9-3 3 3 0 0 1 3-3l85.8-.7c4.5 0 8.1-1.5 8.1-3.4 0-1.8-3.5-3.4-8-3.4l-86-.8a3 3 0 0 1-2.9-3v-1.1a3 3 0 0 1 3-3l85.8-.6c4.5 0 8.1-1.6 8.1-3.4 0-2-3.5-3.4-8-3.5l-86-.8a3 3 0 0 1-2.9-3v-1 4.3Z"/><path d="M204 0a204 204 0 1 0 0 408 204 204 0 0 0 0-408Zm0 382.3c-98.3 0-178.3-80-178.3-178.3 0-98.3 80-178.3 178.3-178.3 98.3 0 178.3 80 178.3 178.3 0 98.3-80 178.3-178.3 178.3Z"/>
    </g>
  </svg>
)

export default RestaurantIcon

